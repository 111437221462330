import React from 'react';
import { AccompaniedViewProps } from './types';
import Button from '../../../widget-ui-toolkit/Button';
import RadioButton from '../../../widget-ui-toolkit/RadioButton';
import styles from './AccompaniedView.module.css';
import FormattedMessage from '../../../widget-ui-toolkit/FormattedMessage';
import { isAccompaniedOptions } from '../constants';

const LOCALIZE_PREFIX = 'widget.accompanied';

const AccompaniedView = ({
  accompaniedOption,
  setAccompaniedOption,
  continueBooking,
}: AccompaniedViewProps) => {
  return (
    <>
      <div className={styles.wrapper}>
        <p className={styles.info}>
          <FormattedMessage id={`${LOCALIZE_PREFIX}.how_attending_question`} />
        </p>
        <RadioButton
          selected={accompaniedOption}
          radioOptions={isAccompaniedOptions}
          setSelected={setAccompaniedOption}
          renderAsCheckbox={false}
        />
      </div>
      <Button disabled={accompaniedOption.id === -1} onClick={continueBooking}>
        <FormattedMessage id={`${LOCALIZE_PREFIX}.button.continue`} />
      </Button>
    </>
  );
};

export default AccompaniedView;
