import React from 'react';
import { SelectPatientTypeViewProps } from './types';
import RadioButton from '../../../widget-ui-toolkit/RadioButton';
import styles from './SelectPatientTypeView.module.css';
import FormattedMessage from '../../../widget-ui-toolkit/FormattedMessage';
import { selectPatientTypeOptions } from '../constants';

const LOCALIZE_PREFIX = 'widget.select_patient_type';

const SelectPatientTypeView = ({
  setPatientTypeOption,
  patientTypeOption,
}: SelectPatientTypeViewProps) => {
  return (
    <>
      <div className={styles.wrapper}>
        <p className={styles.info}>
          <FormattedMessage id={`${LOCALIZE_PREFIX}.patient_type_question`} />
        </p>
        <RadioButton
          selected={patientTypeOption}
          radioOptions={selectPatientTypeOptions}
          setSelected={setPatientTypeOption}
          renderAsCheckbox={false}
        />
      </div>
    </>
  );
};

export default SelectPatientTypeView;
