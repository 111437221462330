enum Environments {
  LOCAL = 'LOCAL',
  TEST = 'TEST',
  TEST_NO_GROWTH = 'TEST_NO_GROWTH',
  TEST_DEMO = 'TEST_DEMO',
  PRODUCTION = 'PRODUCTION',
  DEFAULT = 'DEFAULT',
}

export const isLocalEnvironment = () =>
  window.location.host.includes('localhost');
export const isTestEnvironment = () =>
  isTestUkEnvironment() || isTestNoEnvironment();
export const isTestUkEnvironment = () =>
  window.location.host.includes('test-uk.booking.livi.co.uk');
export const isTestNoEnvironment = () =>
  window.location.host.includes('test-no-growth.booking.livi.co.uk');
export const isTestDemoEnvironment = () =>
  window.location.host.includes('test-demo.booking.livi.co.uk');
export const isProdEnvironment = () =>
  window.location.host.includes('booking.livi.co.uk') && !isTestEnvironment();

export const getApiUrl = () => {
  if (isLocalEnvironment()) {
    return 'localhost.se';
  }
  if (isTestUkEnvironment()) {
    return 'test-uk-growth.kry.se';
  }
  if (isTestNoEnvironment()) {
    return 'test-no-growth.kry.se';
  }
  if (isTestDemoEnvironment()) {
    return 'test-demo.kry.se';
  }
  return 'kry.se';
};

export const getSnowplowCollectorEndpoint = () => {
  if (isTestEnvironment()) {
    return 'analytics.kry.pet';
  }
  if (isLocalEnvironment()) {
    return 'localhost:3000/track';
  }
  return 'snowplowcollector.kry.pet';
};

export const getEnvironment = () => {
  if (isLocalEnvironment()) {
    return Environments.LOCAL;
  }
  if (isTestNoEnvironment()) {
    return Environments.TEST_NO_GROWTH;
  }
  if (isTestDemoEnvironment()) {
    return Environments.TEST_DEMO;
  }
  if (isTestUkEnvironment()) {
    return Environments.TEST;
  }
  if (isProdEnvironment()) {
    return Environments.PRODUCTION;
  }
  return Environments.DEFAULT;
};

export const getAuthRedirectUrl = () => {
  if (isLocalEnvironment()) {
    return 'http://localhost:3000/';
  } else if (isTestUkEnvironment()) {
    return 'https://test-uk.booking.livi.co.uk';
  } else if (isTestNoEnvironment()) {
    return 'https://test-no-growth.booking.livi.co.uk';
  } else if (isTestDemoEnvironment()) {
    return 'https://test-demo.booking.livi.co.uk';
  } else {
    return 'https://booking.livi.co.uk';
  }
};
