import React, { ReactElement, useContext, useState } from 'react';
import Button from '../../widget-ui-toolkit/Button';
import { SizeContext } from '../../utils/SizeContext';
import { CalendarViewProps, MainSubViews, MainViewProps } from './types';
import formatDate from './formatDate';
import FormattedMessage from '../../widget-ui-toolkit/FormattedMessage';
import { ValidationErrors } from '../../utils/validation/types';
import SearchTextInput from '../../widget-ui-toolkit/TextInput/SearchTextInput';
import styles from './MainView.module.css';
import AccompaniedView from './PatientTypeSelector/AccompaniedView';
import { isProdEnvironment } from '../../utils/environment';
import BackButton from '../../widget-ui-toolkit/Button/BackButton';
import ViewTitle from '../../components/ViewTitle';
import SelectPatientTypeView from './PatientTypeSelector/SelectPatientTypeView';
import AdultBookingView from './AdultBooking/AdultBookingView';
import ChildBookingView from './ChildBooking/ChildBookingView';
import ConfirmAdultPatientTypeView from './PatientTypeSelector/ConfirmAdultPatientTypeView';

const LOCALIZE_PREFIX = 'widget.booking.main';

const MainView = ({
  booking,
  onChangeBooking,
  onSelectNewAppointmentDateTime,
  appointmentDateTime,
  onBookAppointment,
  isSlotUnavailable,
  isLiviPractice,
  partnership,
  accompaniedOption,
  setAccompaniedOption,
  isChildBookingEnabled,
  patientTypeOption,
  setPatientTypeOption,
}: MainViewProps): ReactElement => {
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const onSizeUpdate = useContext(SizeContext);
  const [subView, setSubView] = useState<MainSubViews>(
    isLiviPractice && !isProdEnvironment()
      ? MainSubViews.Accompanied
      : MainSubViews.Booking
  );

  function handleChecked() {
    onChangeBooking({ ...booking, optionChecked: !booking.optionChecked });
    onSizeUpdate();
  }

  function handleOptionChanged() {
    onChangeBooking({
      ...booking,
      optionChecked: true,
      isChild: !booking.isChild,
    });
    onSizeUpdate();
  }

  const submitBooking = () => {
    onBookAppointment();
  };

  function getCalendarViewProps() {
    const calendarViewProps: CalendarViewProps = {};
    if (isChildBookingEnabled) {
      calendarViewProps.nhsPatientSelectorEnabled = true;
    }
    if (!isChildBookingEnabled) {
      calendarViewProps.nhsConfirmPatientAdultViewEnabled = true;
    }
    if (booking.optionChecked && !booking.isChild) {
      calendarViewProps.nhsAdultPatientViewEnabled = true;
    }
    if (booking.optionChecked && booking.isChild) {
      calendarViewProps.nhsChildPatientViewEnabled = true;
    }

    return calendarViewProps;
  }

  return (
    <>
      {isLiviPractice && (
        <ViewTitle>
          {subView === MainSubViews.Accompanied && (
            <div className={styles.appTitle}>
              <FormattedMessage id={`${LOCALIZE_PREFIX}.book_cta`} />
            </div>
          )}
          {subView === MainSubViews.Booking && (
            <BackButton onClick={() => setSubView(MainSubViews.Accompanied)} />
          )}
        </ViewTitle>
      )}
      <div
        onClick={onSelectNewAppointmentDateTime}
        className={styles.appointmentDateTime}
      >
        <SearchTextInput
          validationError={
            isSlotUnavailable ? ValidationErrors.SlotUnavailable : undefined
          }
          defaultValue={formatDate(appointmentDateTime)}
          label={`${LOCALIZE_PREFIX}.selected_appointment_label`}
          id="appointment"
          imageAltText="Select date and time for appointment"
        />
      </div>
      {subView === MainSubViews.Accompanied && (
        <AccompaniedView
          accompaniedOption={accompaniedOption}
          setAccompaniedOption={setAccompaniedOption}
          continueBooking={() => setSubView(MainSubViews.Booking)}
        />
      )}
      {subView === MainSubViews.Booking && (
        <>
          {getCalendarViewProps().nhsPatientSelectorEnabled && (
            <SelectPatientTypeView
              patientTypeOption={patientTypeOption}
              setPatientTypeOption={(patientOption) => {
                setPatientTypeOption(patientOption);
                handleOptionChanged();
              }}
            />
          )}
          {getCalendarViewProps().nhsConfirmPatientAdultViewEnabled && (
            <ConfirmAdultPatientTypeView
              isLiviPractice={isLiviPractice}
              isAccompanied={accompaniedOption.value}
              defaultChecked={booking.optionChecked}
              onChange={handleChecked}
            />
          )}
          {getCalendarViewProps().nhsAdultPatientViewEnabled && (
            <AdultBookingView
              booking={booking}
              onChangeBooking={onChangeBooking}
              isLiviPractice={isLiviPractice}
              accompaniedOption={accompaniedOption}
              setValidationState={setIsFormValid}
            />
          )}
          {getCalendarViewProps().nhsChildPatientViewEnabled && (
            <ChildBookingView
              booking={booking}
              onChangeBooking={onChangeBooking}
              setValidationState={setIsFormValid}
            />
          )}
        </>
      )}
      {(accompaniedOption.value !== undefined ||
        !isLiviPractice ||
        isProdEnvironment()) &&
        subView === MainSubViews.Booking && (
          <Button
            disabled={!isFormValid || isSlotUnavailable}
            onClick={submitBooking}
          >
            <FormattedMessage
              id={`${LOCALIZE_PREFIX}.book_appointment_button`}
            />
          </Button>
        )}
    </>
  );
};

export default MainView;
