const formatDate = (date?: Date): string => {
  if (!date) return '';
  return date.toLocaleString('en-GB', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export default formatDate;
