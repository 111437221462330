import React, { useEffect, useState } from 'react';
import { ChildBookingViewProps } from './types';
import styles from './ChildBookingView.module.css';
import TextInput from '../../../widget-ui-toolkit/TextInput';
import { ValidationErrors } from '../../../utils/validation/types';
import { validateNhsNumber } from '../../../utils/validation/nhs-validation';
import { validatePhoneNumber } from '../../../utils/validation/phone-validation';
import FormattedMessage from '../../../widget-ui-toolkit/FormattedMessage';
import Checkbox from '../../../widget-ui-toolkit/Checkbox';
import { validateName } from '../../../utils/validation/name-validation';
import { validateChildDateOfBirth } from '../../../utils/validation/date-of-birth-validation';

const LOCALIZE_PREFIX = 'widget.booking.main.child';

const ChildBookingView = ({
  booking,
  onChangeBooking,
  setValidationState,
}: ChildBookingViewProps) => {
  const [isValidPatientNhsNumber, setIsValidPatientNhsNumber] = useState<
    ValidationErrors | undefined
  >(
    booking.patientNhsNumber
      ? validateNhsNumber(booking.patientNhsNumber)
      : undefined
  );
  const [isValidPatientName, setIsValidPatientName] = useState<
    ValidationErrors | undefined
  >(booking.patientName ? validateName(booking.patientName) : undefined);
  const [isValidPatientDob, setIsValidPatientDob] = useState<
    ValidationErrors | undefined
  >(
    booking.patientDateOfBirth
      ? validateChildDateOfBirth(booking.patientDateOfBirth)
      : undefined
  );
  const [
    isValidLegalGuardianNhsNumber,
    setIsValidLegalGuardianNhsNumber,
  ] = useState<ValidationErrors | undefined>(
    booking.legalGuradianNhsNumber
      ? validateNhsNumber(booking.legalGuradianNhsNumber)
      : undefined
  );
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState<
    ValidationErrors | undefined
  >(booking.phoneNumber ? validatePhoneNumber(booking.phoneNumber) : undefined);

  function patientNhsValidation() {
    setIsValidPatientNhsNumber(validateNhsNumber(booking.patientNhsNumber));
  }

  function patientNameValidation() {
    setIsValidPatientName(validateName(booking.patientName));
  }

  function patientDobValidation() {
    setIsValidPatientDob(validateChildDateOfBirth(booking.patientDateOfBirth));
  }

  function phoneNumberValidation() {
    setIsValidPhoneNumber(validatePhoneNumber(booking.phoneNumber));
  }

  function legalGuardianNhsValidation() {
    setIsValidLegalGuardianNhsNumber(
      validateNhsNumber(booking.legalGuradianNhsNumber)
    );
  }

  useEffect(() => {
    const isPatientNhsValid = validateNhsNumber(booking.patientNhsNumber);
    if (isPatientNhsValid === ValidationErrors.NoError) {
      setIsValidPatientNhsNumber(isPatientNhsValid);
    } else {
      setIsValidPatientNhsNumber(undefined);
    }
  }, [booking.patientNhsNumber]);

  useEffect(() => {
    const isPatientNameValid = validateName(booking.patientName);
    if (isPatientNameValid === ValidationErrors.NoError) {
      setIsValidPatientName(isPatientNameValid);
    } else {
      setIsValidPatientName(undefined);
    }
  }, [booking.patientName]);

  useEffect(() => {
    const isPatientDobValid = validateChildDateOfBirth(
      booking.patientDateOfBirth
    );
    if (isPatientDobValid === ValidationErrors.NoError) {
      setIsValidPatientDob(isPatientDobValid);
    } else {
      setIsValidPatientDob(undefined);
    }
  }, [booking.patientDateOfBirth]);

  useEffect(() => {
    const isPhoneNumberValid = validatePhoneNumber(booking.phoneNumber);
    if (isPhoneNumberValid === ValidationErrors.NoError) {
      setIsValidPhoneNumber(isPhoneNumberValid);
    } else {
      setIsValidPhoneNumber(undefined);
    }
  }, [booking.phoneNumber]);

  useEffect(() => {
    const isLegalGuardianNhsValid = validateNhsNumber(
      booking.legalGuradianNhsNumber
    );
    if (isLegalGuardianNhsValid === ValidationErrors.NoError) {
      setIsValidLegalGuardianNhsNumber(isLegalGuardianNhsValid);
    } else {
      setIsValidLegalGuardianNhsNumber(undefined);
    }
  }, [booking.legalGuradianNhsNumber]);

  useEffect(() => {
    setValidationState(
      isValidPatientNhsNumber === ValidationErrors.NoError &&
        isValidPatientName === ValidationErrors.NoError &&
        isValidPatientDob === ValidationErrors.NoError &&
        isValidLegalGuardianNhsNumber === ValidationErrors.NoError &&
        isValidPhoneNumber === ValidationErrors.NoError &&
        booking.legalGuardianCheckboxChecked &&
        Boolean(booking.optionalNotes)
    );
  }, [
    isValidPatientNhsNumber,
    isValidPatientName,
    isValidPatientDob,
    isValidLegalGuardianNhsNumber,
    isValidPhoneNumber,
    booking.optionChecked,
    booking.legalGuardianCheckboxChecked,
    booking.optionalNotes,
  ]);

  function handleChecked() {
    onChangeBooking({
      ...booking,
      legalGuardianCheckboxChecked: !booking.legalGuardianCheckboxChecked,
    });
  }

  return (
    <>
      <div className={styles.inputWrapper}>
        <p className={styles.info}>
          <FormattedMessage id={`${LOCALIZE_PREFIX}.child_details_label`} />
        </p>
        <TextInput
          value={booking.patientNhsNumber}
          validationError={isValidPatientNhsNumber}
          onChange={(e) =>
            onChangeBooking({ ...booking, patientNhsNumber: e.target.value })
          }
          onBlur={patientNhsValidation}
          label={`${LOCALIZE_PREFIX}.patient_nhs_number_label`}
          id="patientNhsNumber"
        />
        <TextInput
          value={booking.patientName}
          validationError={isValidPatientName}
          onChange={(e) =>
            onChangeBooking({ ...booking, patientName: e.target.value })
          }
          onBlur={patientNameValidation}
          label={`${LOCALIZE_PREFIX}.patient_name_label`}
          id="patientName"
        />
        <TextInput
          value={booking.patientDateOfBirth}
          validationError={isValidPatientDob}
          onChange={(e) =>
            onChangeBooking({ ...booking, patientDateOfBirth: e.target.value })
          }
          onBlur={patientDobValidation}
          label={`${LOCALIZE_PREFIX}.patient_date_of_birth_label`}
          id="patientDateOfBirth"
        />
      </div>
      <div className={styles.inputWrapper}>
        <p className={styles.info}>
          <FormattedMessage
            id={`${LOCALIZE_PREFIX}.legal_guardian_details_label`}
          />
        </p>
        <TextInput
          value={booking.legalGuradianNhsNumber}
          validationError={isValidLegalGuardianNhsNumber}
          onChange={(e) =>
            onChangeBooking({
              ...booking,
              legalGuradianNhsNumber: e.target.value,
            })
          }
          onBlur={legalGuardianNhsValidation}
          label={`${LOCALIZE_PREFIX}.legal_guardian_nhs_number_label`}
          id="legalGuardianNhsNumber"
        />
        <TextInput
          value={booking.phoneNumber}
          validationError={isValidPhoneNumber}
          onChange={(e) =>
            onChangeBooking({ ...booking, phoneNumber: e.target.value })
          }
          onBlur={phoneNumberValidation}
          label={`${LOCALIZE_PREFIX}.legal_guardian_mobile_number_label`}
          id="mobile"
        />
        <Checkbox
          defaultChecked={booking.legalGuardianCheckboxChecked}
          onClick={handleChecked}
        >
          <FormattedMessage
            id={`${LOCALIZE_PREFIX}.legal_guardian_checkbox_label`}
          />
        </Checkbox>
        <TextInput
          value={booking.optionalNotes}
          onChange={(e) =>
            onChangeBooking({ ...booking, optionalNotes: e.target.value })
          }
          label={`${LOCALIZE_PREFIX}.appointment_reason_label_mandatory`}
          id="reason"
          inputType="textarea"
        />
      </div>
    </>
  );
};

export default ChildBookingView;
