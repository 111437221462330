import React, { Component, ErrorInfo, useState } from 'react';
import ErrorView from './ErrorView';
import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import { ErrorBoundaryProps, ErrorBoundaryState } from './types';
import { ReactComponent as PowerExclamation } from '../../../media/PowerExclamation.svg';
import { AppRoutes } from '../../ContentViewRouter.types';
import { trackEvent } from '../../utils/eventTracking';
import styles from './ErrorView.module.css';

const UNAUTHORIZED_LOCALIZE_PREFIX = 'widget.error.unauthorized';
const GENERAL_ERROR_LOCALIZE_PREFIX = 'widget.error.general_error';
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state: ErrorBoundaryState = {
    hasError: false,
    responseInterceptor: undefined,
    authError: false,
  };
  private responseInterceptor?: number;
  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
    // TODO look into using error boundary
    Bugsnag.notify(error);
  }
  componentDidMount() {
    this.responseInterceptor = axios.interceptors.response.use(
      (res) => res,
      this.handleAxiosError
    );
  }
  componentWillUnmount() {
    if (this.responseInterceptor !== undefined) {
      // Remove handlers, so Garbage Collector will get rid of if WrappedComponent will be removed
      axios.interceptors.response.eject(this.responseInterceptor);
    }
  }
  handleAxiosError = (error: any) => {
    console.error('intercepted axios response');
    if (error.response?.status === 401) {
      this.setState({ authError: true });
      this.props.onSetAuthenticated(undefined);
    }
    if (error.response?.status !== 400) {
      this.setState({ hasError: true });
    } else if (error.message === 'Network Error') {
      this.setState({ hasError: true });
    }
    trackEvent({ action: 'general-error-displayed' });
    return Promise.reject(error.response || error);
  };

  public render() {
    if (this.state.hasError) {
      if (this.state.authError) {
        return (
          <ErrorView
            onButtonClick={() => {
              this.setState({ hasError: false, authError: false });
              this.props.onChangeRoute(AppRoutes.LoginFlow);
            }}
            header={`${UNAUTHORIZED_LOCALIZE_PREFIX}.subheader`}
            info={`${UNAUTHORIZED_LOCALIZE_PREFIX}.error_description`}
            buttonText={`${UNAUTHORIZED_LOCALIZE_PREFIX}.login_button`}
            image={<PowerExclamation className={styles.errorImage} />}
          />
        );
      }
      return (
        <ErrorView
          onButtonClick={() => this.setState({ hasError: false })}
          header={`${GENERAL_ERROR_LOCALIZE_PREFIX}.subheader`}
          info={`${GENERAL_ERROR_LOCALIZE_PREFIX}.error_description`}
          buttonText={`${GENERAL_ERROR_LOCALIZE_PREFIX}.reload_widget_button`}
        />
      );
    }
    return this.props.children;
  }
}

export function useErrorHandler<P = Error>(
  givenError?: P | null | undefined
): React.Dispatch<React.SetStateAction<P | null>> {
  const [error, setError] = useState<P | null>(null);
  if (givenError) throw givenError;
  if (error) throw error;
  return setError;
}

export default ErrorBoundary;
