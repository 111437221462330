import { getEnvironment } from './environment';

export function isElectron() {
  // Main process
  if (
    typeof process !== 'undefined' &&
    typeof process.versions === 'object' &&
    !!process.versions.electron
  ) {
    return true;
  }

  // Detect the user agent when the `nodeIntegration` option is set to true
  if (
    typeof navigator === 'object' &&
    typeof navigator.userAgent === 'string' &&
    navigator.userAgent.indexOf('Electron') >= 0
  ) {
    return true;
  }

  return false;
}

export function resizeElectronWindow(width: number, height: number): void {
  if (isElectron()) {
    window.require('electron').ipcRenderer.send('resize', width, height);
  }
}

export function closeElectronNhsAuth(): void {
  if (isElectron()) {
    window.require('electron').ipcRenderer.send('closeNhs');
  }
}

export function openNhsAuth(): void {
  if (isElectron()) {
    window.require('electron').ipcRenderer.send('nhsAuth');
  } else {
    window.location.href = encodeURI(
      'https://fs.nhs.net/adfs/oauth2/authorize/?client_id=5c05ecfc-2a13-441a-b433-46d15ee3ccf4&redirect_uri=https://kry.se/api/uk-booking-management/nhsmail/auth/' +
        getEnvironment() +
        '&response_type=code'
    );
  }
}

export function addElectronNhsAuthCallbacks(
  successCallback: (event: any, arg: any) => void,
  errorCallback: (event: any, arg: any) => void
): void {
  if (isElectron()) {
    window
      .require('electron')
      .ipcRenderer.on('nhs-auth-token', successCallback);
    window.require('electron').ipcRenderer.on('nhs-auth-error', errorCallback);
  }
}

export function removeElectronNhsAuthCallbacks(
  successCallback: (event: any, arg: any) => void,
  errorCallback: (event: any, arg: any) => void
): void {
  if (isElectron()) {
    window
      .require('electron')
      .ipcRenderer.removeListener('nhs-auth-token', successCallback);
    window
      .require('electron')
      .ipcRenderer.removeListener('nhs-auth-error', errorCallback);
  }
}

export function getVersion(): string {
  if (isElectron()) {
    return window.require('electron').remote.app.getVersion();
  }
  return 'web';
}

export function electronOpenUrl(url: string): void {
  if (isElectron()) {
    const open = window.require('open');
    open(url);
  }
}
