import React from 'react';
import Header from './widget-ui-toolkit/Header';
import { Channel } from './widget-ui-toolkit/Header/types';
import Menu from './components/Menu';
import ContentView from './ContentViewRouter';
import { AppRoutes } from './ContentViewRouter.types';
import { SizeContext } from './utils/SizeContext';
import { AppProps, AppState } from './App.types';
import {
  HEADER_HEIGHT,
  HEADER_WIDTH,
} from './widget-ui-toolkit/Header/constants';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { getLang } from './api/api';
import styles from './App.module.css';
import {
  resizeElectronWindow,
  closeElectronNhsAuth,
  getVersion,
  isElectron,
} from './utils/platformUtils';
import {
  initTracker,
  trackEvent,
  setTrackerUserId,
} from './utils/eventTracking';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { getAuthRedirectUrl } from './utils/environment';

const ONE_HOUR_IN_MS = 60 * 60 * 1000;

async function fetchAndStoreLang(): Promise<void> {
  const lang = await getLang();
  localStorage.setItem('lang', JSON.stringify(lang));
}

const version = getVersion();

Bugsnag.start({
  apiKey: '831c506e8e2d1ac52c17fdb3072bcf51',
  appVersion: version,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: Object.values(Channel).filter(
    (x) => x !== Channel.Devel
  ),
});

const BugsnagErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(
  React
);

class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      route: undefined,
      authenticated: window.sessionStorage.getItem('jwtToken')
        ? true
        : undefined,
    };
    this.onSizeUpdate = this.onSizeUpdate.bind(this);
  }

  handleClose = () => {
    trackEvent({ action: 'widget-collapsed' });
    closeElectronNhsAuth();
    this.setState({ route: undefined });
  };

  handleChangeRoute = (route?: AppRoutes) => {
    if (route !== undefined && route !== this.state.route) {
      this.setState({ route });
      if (route === AppRoutes.BookingFlow)
        trackEvent({ action: 'booking-flow-opened' });
    } else {
      closeElectronNhsAuth();
      this.setState({ route: undefined });
      trackEvent({ action: 'widget-collapsed' });
    }
  };

  async componentDidMount() {
    this.forceUpdate(); //TODO: remove this or investigate if to remove the page reload op.
    await fetchAndStoreLang();
    const fetchAndStoreLangInterval = setInterval(async () => {
      await fetchAndStoreLang();
    }, ONE_HOUR_IN_MS);
    this.setState({ fetchAndStoreLangInterval });
    initTracker();
  }

  async componentWillUnmount() {
    if (this.state.fetchAndStoreLangInterval)
      clearInterval(this.state.fetchAndStoreLangInterval);
  }

  componentDidUpdate() {
    if (!isElectron()) {
      document.body.style.overflowY = 'auto';
      document.body.style.overflowX = 'auto';
    }

    const menuHeight = this.state.authenticated
      ? HEADER_HEIGHT * (AppRoutes.__LENGTH - 1)
      : HEADER_HEIGHT;
    const height =
      (document.getElementById('main')?.clientHeight || menuHeight) +
      HEADER_HEIGHT;
    const width =
      (document.getElementById('main')?.clientWidth || 0) + HEADER_WIDTH;
    if (
      height !== this.state.contentHeight ||
      width !== this.state.contentWidth
    ) {
      resizeElectronWindow(width, height);
      this.setState({
        contentHeight: height,
        contentWidth: width,
      });
    }
  }

  onSizeUpdate = () => {
    this.forceUpdate();
  };

  handleSetAuthenticated = (authenticated?: boolean) => {
    this.setState({ authenticated });
  };

  render() {
    const { route, authenticated } = { ...this.state };
    return (
      <BugsnagErrorBoundary>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <div className={styles.appWrapper}>
                  <div
                    className={
                      isElectron()
                        ? styles.app
                        : this.state.route !== undefined
                        ? styles.webAppExpanded
                        : styles.webAppCollapsed
                    }
                  >
                    <SizeContext.Provider value={this.onSizeUpdate}>
                      <Header onClose={this.handleClose} route={route} />
                      <ContentView
                        route={route}
                        onSetAuthenticated={this.handleSetAuthenticated}
                        onChangeRoute={this.handleChangeRoute}
                        onCloseContentView={this.handleClose}
                        authenticated={authenticated}
                      />
                      <Menu
                        route={route}
                        onClick={this.handleChangeRoute}
                        authenticated={authenticated}
                      />
                    </SizeContext.Provider>
                  </div>
                </div>
              }
            />
            <Route
              path="/auth"
              element={
                <Auth onSetAuthenticated={this.handleSetAuthenticated} />
              }
            />
          </Routes>
        </BrowserRouter>
      </BugsnagErrorBoundary>
    );
  }
}

const Auth = ({
  onSetAuthenticated,
}: {
  onSetAuthenticated: (auth: boolean) => void;
}) => {
  const urlObject = new URL(window.location.href);
  const urlParams = new URLSearchParams(urlObject.search);
  const jwtToken = urlParams.get('token') || '';
  const userId = urlParams.get('userId') || '';
  window.sessionStorage.setItem('jwtToken', jwtToken);
  window.localStorage.setItem('userId', userId);
  setTrackerUserId(userId);
  onSetAuthenticated(true);
  window.location.href = getAuthRedirectUrl();
  return <></>;
};

export default App;
