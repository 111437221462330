import React, { ReactElement } from 'react';
import Alert from '../../widget-ui-toolkit/Alert';
import Loader from '../../widget-ui-toolkit/Loader';
import { LoadingViewProps } from './types';
import FormattedMessage from '../../widget-ui-toolkit/FormattedMessage';
import styles from './LoadingView.module.css';

const LoadingView = ({ message }: LoadingViewProps): ReactElement => {
  return (
    <div className={styles.loadingView}>
      <label className={styles.message}>
        {message && <FormattedMessage id={message} />}
      </label>
      <Alert>
        <Loader size={16} />
      </Alert>
    </div>
  );
};

export default LoadingView;
