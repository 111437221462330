export enum AppRoutes {
  AppointmentStatus,
  BookingFlow,
  Miscellaneous,
  LoginFlow,
  __LENGTH,
}

export type ContentViewRouterProps = {
  route?: AppRoutes;
  onCloseContentView: () => void;
  onChangeRoute: (route?: AppRoutes) => void;
  onSetAuthenticated: (authenticated?: boolean) => void;
  authenticated?: boolean;
};
