import React, { ReactElement } from 'react';
import Button from './Button';
import { LinkButtonProps } from './types';
import cn from 'classnames';
import styles from './Button.module.css';
import { electronOpenUrl, isElectron } from '../../utils/platformUtils';

const LinkButton = ({ children, href }: LinkButtonProps): ReactElement => {
  if (isElectron()) {
    return (
      <Button primary onClick={() => electronOpenUrl(href)}>
        {children}
      </Button>
    );
  }
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={cn(styles.link)}
    >
      <Button primary>{children}</Button>
    </a>
  );
};

export default LinkButton;
