import React, { ReactElement } from 'react';
import { FailViewProps } from './types';
import ErrorInformation from '../Error/ErrorInformation';
import Button from '../../widget-ui-toolkit/Button';
import FormattedMessage from '../../widget-ui-toolkit/FormattedMessage';
import styles from './FailView.module.css';

const LOCALIZE_PREFIX = 'widget.login.error';

const FailView = ({ onRetry }: FailViewProps): ReactElement => {
  return (
    <div className={styles.failViewWrapper}>
      <ErrorInformation
        header={`${LOCALIZE_PREFIX}.subheader`}
        info={`${LOCALIZE_PREFIX}.error_description`}
      />
      <strong className={styles.supportPhoneNumber}>
        <FormattedMessage id={`${LOCALIZE_PREFIX}.support_phone_number`} />
      </strong>
      <Button onClick={onRetry}>
        <FormattedMessage id={`${LOCALIZE_PREFIX}.try_again_button`} />
      </Button>
    </div>
  );
};

export default FailView;
