function camelize(data: string): string {
  return data.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace('-', '').replace('_', '')
  );
}

type JsonObject = Record<string, object | [] | string>;

export function camelizeJson(data: JsonObject): JsonObject {
  const output = {} as JsonObject;
  for (const key in data) {
    if (Array.isArray(data[key])) {
      output[camelize(key)] = Object.values(
        camelizeJson(data[key] as JsonObject)
      );
    } else if (typeof data[key] === 'object') {
      output[camelize(key)] = camelizeJson(data[key] as JsonObject);
    } else {
      output[camelize(key)] = data[key];
    }
  }
  return output;
}
