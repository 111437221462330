import React, { ReactElement } from 'react';
import { ErrorInformationProps } from './types';
import FormattedMessage from '../../widget-ui-toolkit/FormattedMessage';
import { ReactComponent as ErrorMessageSad } from '../../../media/ErrorMessageSad.svg';
import styles from './ErrorInformation.module.css';
import cn from 'classnames';

const ErrorInformation = ({
  header,
  info,
  image = <ErrorMessageSad className={styles.errorImage} />,
}: ErrorInformationProps): ReactElement => {
  return (
    <>
      {image}
      <h2 className={styles.errorTitle}>
        <FormattedMessage id={header} />
      </h2>
      <p className={cn('soft', 'center', styles.errorInfo)}>
        <FormattedMessage id={info} />
      </p>
    </>
  );
};

export default ErrorInformation;
