import { ValidationErrors } from './types';
import { parsePhoneNumberWithError, PhoneNumber } from 'libphonenumber-js/max';

export const validatePhoneNumber = (
  phoneNumber: string = ''
): ValidationErrors => {
  try {
    const parsedPhoneNumber: PhoneNumber = parsePhoneNumberWithError(
      phoneNumber,
      'GB'
    );
    if (parsedPhoneNumber.getType() !== 'MOBILE')
      return ValidationErrors.PhoneNumberError;
    return ValidationErrors.NoError;
  } catch (e) {
    return ValidationErrors.PhoneNumberError;
  }
};
