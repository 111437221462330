import React, { ReactElement } from 'react';
import Alert from '../../../widget-ui-toolkit/Alert';
import { ReactComponent as Calendar } from '../../../../media/Calendar.svg';
import FormattedMessage from '../../../widget-ui-toolkit/FormattedMessage';
import styles from './FullyBookedView.module.css';

const LOCALIZE_PREFIX = 'widget.booking.fully_booked';

const FullyBookedView = (): ReactElement => {
  return (
    <Alert className={styles.fullyBookedView}>
      <Calendar className={styles.icon} />
      <p className={styles.infoWrapper}>
        <FormattedMessage
          id={`${LOCALIZE_PREFIX}.no_available_appointment_slots_alert`}
        />
      </p>
    </Alert>
  );
};

export default FullyBookedView;
