import React from 'react';
import { ReactElement } from 'react';
import WeekInput from '../WeekInput/WeekInput';
import TimeScheduleInput from '../TimeScheduleInput/TimeScheduleInput';
import DateTimeMonthYearLabel from './DateTimeMonthYearLabel';
import { TimeSlot } from '../../components/TimeScheduleInput/types';
import { DateTimeScheduleInputProps } from './types';
import FormattedMessage from '../../widget-ui-toolkit/FormattedMessage';
import styles from './DateTimeScheduleInput.module.css';
import { extractUnavailabilityMessage } from './extractUnavailabilityMessage';

const LOCALIZE_PREFIX = 'widget.booking.calendar';

const DateTimeScheduleInput = ({
  onChange,
  slots,
  value,
  maxDaysEnabled,
}: DateTimeScheduleInputProps): ReactElement => {
  const toDisplay = value || new Date();
  return (
    <div className={styles.dateTimeScheduleInput}>
      <label>
        <FormattedMessage id={`${LOCALIZE_PREFIX}.select_date_label`} />
      </label>
      <DateTimeMonthYearLabel date={value} />
      <WeekInput
        value={value}
        onChange={(date) => onChange(date, false)}
        maxDaysEnabled={maxDaysEnabled}
      />
      <label>
        <FormattedMessage id={`${LOCALIZE_PREFIX}.select_time_label`} />
      </label>
      <TimeScheduleInput
        onChange={(date, isValid) => onChange(date, isValid)}
        slots={slots.availableSlots.filter(
          (x: TimeSlot) => x.date.toDateString() === toDisplay.toDateString()
        )}
        value={value}
        unavailabilityMessage={extractUnavailabilityMessage(
          slots.unavailabilityMessages,
          value
        )}
      />
    </div>
  );
};

export default DateTimeScheduleInput;
