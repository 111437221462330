import React from 'react';
import cn from 'classnames';
import CircledCross from '../../../media/CircledCross.svg';
import Check from '../../../media/Check.svg';
import { TextInputProps } from './types';
import FormattedMessage from '../../widget-ui-toolkit/FormattedMessage';
import styles from './TextInput.module.css';

const TextInput: React.FC<TextInputProps> = ({
  value,
  validationError,
  children,
  label,
  id,
  inputType = 'input',
  ...props
}: TextInputProps) => {
  const setValue = (input: string) => {
    value = input;
  };

  const fieldProps = {
    ...props,
    className: cn(
      props.className ? props.className : styles.textInput,
      validationError === '' && styles.valid,
      Boolean(validationError) && styles.invalid
    ),
    type: 'text',
    value,
    id,
  };

  return (
    <>
      {label && (
        <label htmlFor={id} className={styles.textInputLabel}>
          <FormattedMessage id={label} />
        </label>
      )}
      <div className={styles.textInputWrapper}>
        {Boolean(validationError) && (
          <i className={styles.validationIcon}>
            <img src={CircledCross} alt="Cross" />
          </i>
        )}
        {validationError === '' && (
          <i className={styles.validationIcon}>
            <img src={Check} alt="Check" />
          </i>
        )}
        {children}
        {inputType === 'textarea' ? (
          <textarea
            onChange={(e) => setValue(e.target.value)}
            {...fieldProps}
          />
        ) : (
          <input
            autoComplete="off"
            onChange={(e) => setValue(e.target.value)}
            {...fieldProps}
          />
        )}
        <small className={styles.validationError}>
          {validationError && <FormattedMessage id={validationError} />}
        </small>
      </div>
    </>
  );
};

export default TextInput;
