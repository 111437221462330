import React, { ReactElement } from 'react';
import TextInput from '../../../widget-ui-toolkit/TextInput';
import Button from '../../../widget-ui-toolkit/Button';
import { AdultConfirmationViewProps } from './types';
import formatDate from '../formatDate';
import FormattedMessage from '../../../widget-ui-toolkit/FormattedMessage';
import styles from './AdultConfirmationView.module.css';
import { getAccompaniedLocalisePrefix } from '../../../utils/copy';

const LOCALIZE_PREFIX = 'widget.booking.confirmation';

const AdultConfirmationView = ({
  onClose,
  booking,
  isAccompanied,
}: AdultConfirmationViewProps): ReactElement => {
  return (
    <>
      <h1>
        <FormattedMessage id={`${LOCALIZE_PREFIX}.subheader`} />
      </h1>
      <h3>
        <FormattedMessage id={`${LOCALIZE_PREFIX}.text_message_sent`} />
      </h3>
      <div className={styles.bookingInfoWrapper}>
        <TextInput
          value={formatDate(booking.timeSlot)}
          validationError={undefined}
          disabled
          label={`${LOCALIZE_PREFIX}.appointment_details_label`}
          id="details"
        />
        <TextInput
          value={booking.patientNhsNumber}
          validationError={undefined}
          disabled
          label={`${LOCALIZE_PREFIX}.patient_nhs_number_label`}
          id="nhsNumber"
        />
        <TextInput
          value={booking.phoneNumber}
          validationError={undefined}
          disabled
          label={`${getAccompaniedLocalisePrefix(
            LOCALIZE_PREFIX,
            isAccompanied
          )}.patient_mobile_number_label`}
          id="mobile"
        />
      </div>
      <div className={styles.confirmationInfo}>
        <p>
          <FormattedMessage
            id={`${LOCALIZE_PREFIX}.instruct_patient_to_download_app`}
          />
        </p>
      </div>
      <Button onClick={onClose}>
        <FormattedMessage id={`${LOCALIZE_PREFIX}.close_confirmation_button`} />
      </Button>
    </>
  );
};

export default AdultConfirmationView;
