import React, { useContext, useEffect, ReactElement } from 'react';
import Button from '../../widget-ui-toolkit/Button';
import ErrorInformation from './ErrorInformation';
import { SizeContext } from '../../utils/SizeContext';
import { ErrorViewProps } from './types';
import FormattedMessage from '../../widget-ui-toolkit/FormattedMessage';
import styles from './ErrorView.module.css';

const ErrorView = ({
  onButtonClick,
  buttonText,
  header,
  info,
  image,
}: ErrorViewProps): ReactElement => {
  const onSizeUpdate = useContext(SizeContext);
  useEffect(() => {
    onSizeUpdate();
  }, [onSizeUpdate]);
  return (
    <div className={styles.errorView}>
      <ErrorInformation header={header} info={info} image={image} />
      <Button onClick={onButtonClick}>
        <FormattedMessage id={buttonText} />
      </Button>
    </div>
  );
};

export default ErrorView;
