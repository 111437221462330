import { AppRoutes } from '../../ContentViewRouter.types';

export type LoginFlowRouterProps = {
  onSetAuthenticated: (authenticated?: boolean) => void;
  onChangeRoute: (route?: AppRoutes) => void;
};

export type FailViewProps = {
  onRetry: () => void;
};

export enum LoginViews {
  Main,
  Fail,
  Success,
}

export type SuccessViewProps = {
  onBookAppointment: () => void;
};
