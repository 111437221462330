export const WEEK_DAYS = Object.freeze([
  'monday_short',
  'tuesday_short',
  'wednesday_short',
  'thursday_short',
  'friday_short',
  'saturday_short',
  'sunday_short',
]);

export const MAX_PAGES = 2;
