import React, { ReactElement } from 'react';
import { SelectProps } from './types';
import styles from './Select.module.css';
import FormattedMessage from '../FormattedMessage';

const SelectComponent = ({
  options = [],
  selected,
  onChange,
  disabled,
  className,
  label,
  id,
  validationError,
}: SelectProps): ReactElement => {
  return (
    <>
      <div>
        {label && (
          <label htmlFor={id} className={styles.textInputLabel}>
            <FormattedMessage id={label} />
          </label>
        )}
        <select
          className={className}
          onChange={(e) => onChange(e.target.value)}
          value={selected || undefined}
          disabled={disabled}
          id={id}
        >
          {options.map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <small className={styles.validationError}>
          {validationError && <FormattedMessage id={validationError} />}
        </small>
      </div>
    </>
  );
};

export default SelectComponent;
