import { StructuredEvent } from '@snowplow/browser-tracker';
import { SlotsAndUnavailability } from '../../components/TimeScheduleInput/types';

export const getSnowplowEvent = (schedule: SlotsAndUnavailability) => {
  const slotsAreAvailable = !!schedule.availableSlots[0];
  const availabilityWasThrottled = !!schedule.unavailabilityMessages[0];

  const baseTrackingEvent: Omit<StructuredEvent, 'category'> = {
    action: 'slot-fetch-successful',
  };

  const availableSlotsEvent = () => ({
    ...baseTrackingEvent,
    property: new Date(schedule.availableSlots[0].date).toISOString(),
    label: undefined,
  });

  const noAvailableSlotsEvent = () => ({
    ...baseTrackingEvent,
    property: 'no date available',
    label: availabilityWasThrottled
      ? 'autothrottling'
      : 'no booking availability',
  });

  return slotsAreAvailable ? availableSlotsEvent() : noAvailableSlotsEvent();
};
