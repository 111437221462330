import React, { ReactElement } from 'react';
import { Locale, LocalizeProps } from './types';
import { localize } from './localize';

const FormattedMessage = ({
  id,
  locale = Locale.en,
}: LocalizeProps): ReactElement => {
  return <>{localize(id, locale)}</>;
};

export default FormattedMessage;
