import { getApiUrl } from '../utils/environment';

export const LANG_URL =
  'https://language-resources.kry.se/language/GB/language_booking_tool.json';

export const BOOKING_SLOTS_URL = `https://${getApiUrl()}/api/uk-booking-management/slots`;

export const BOOK_MEETING_URL = `https://${getApiUrl()}/api/uk-booking-management/meeting/book`;

export const BOOKED_APPOINTMENTS_URL = `https://${getApiUrl()}/api/uk-booking-management/bookings`;

export const MANDATORY_FIELDS_URL = `https://${getApiUrl()}/api/uk-booking-management/mandatory-fields/booking`;

export const PRACTICE_SETTINGS_URL = `https://${getApiUrl()}/api/uk-booking-management/practice/settings`;
