import React, { ReactElement } from 'react';
import cn from 'classnames';
import { MenuItemProps } from './types';
import MenuItemDivider from './MenuItemDivider';
import styles from './Menu.module.css';

const MenuItem = ({
  selected,
  onClick,
  label,
  children,
  expanded,
}: MenuItemProps): ReactElement => {
  return (
    <>
      <div
        aria-label={label}
        title={label}
        role="button"
        className={cn(
          styles.item,
          selected && styles.selected,
          expanded && styles.expanded
        )}
        onClick={onClick}
      >
        <MenuItemDivider />
        {children}
      </div>
    </>
  );
};

export default MenuItem;
