import { useEffect, useState } from 'react';
import {
  SlotsAndUnavailability,
  SlotsAndUnavailabilityWithRefetch,
  TimeSlot,
} from '../../components/TimeScheduleInput/types';
import { trackEvent } from '../../utils/eventTracking';
import { getBookingSlots, getPracticeSettings } from '../../api/api';
import { BookingSlotsReponseList } from '../../api/types';
import { BOOKING_HORIZON_DAYS } from '../../constants';
import { getSnowplowEvent } from './getSnowplowEvent';
import { PracticeSettingsProps } from './types';

export function transformTimeSlotsList(slots: BookingSlotsReponseList) {
  const timeSlots = [] as TimeSlot[];
  const slotsInterval: number =
    slots[0] && slots[0].endTimeExclusive - slots[0].startTimeInclusive;

  slots.forEach((s, i) => {
    if (i === 0) {
      timeSlots.push({
        date: new Date(s.startTimeInclusive),
        disabled: false,
      });
    } else {
      if (
        s.startTimeInclusive !== slots[i - 1].endTimeExclusive &&
        new Date(s.startTimeInclusive).getDate() ===
          new Date(slots[i - 1].endTimeExclusive).getDate()
      ) {
        let counter = 0;
        while (
          s.startTimeInclusive >
          slots[i - 1].endTimeExclusive + slotsInterval * counter
        ) {
          timeSlots.push({
            disabled: true,
            date: new Date(
              slots[i - 1].endTimeExclusive + slotsInterval * counter
            ),
          });
          counter++;
        }
      }
      timeSlots.push({
        date: new Date(s.startTimeInclusive),
        disabled: false,
      });
    }
  });
  return timeSlots;
}

async function fetchSlotsAndDayMessages() {
  const now = Date.now();
  const nextWeek = new Date().setDate(
    new Date().getDate() + BOOKING_HORIZON_DAYS - 1
  );
  const response = await getBookingSlots(now, nextWeek);
  const availableSlots = transformTimeSlotsList(response.slots);
  const unavailabilityMessages = response.dayMessages;
  return { availableSlots, unavailabilityMessages };
}

export const useAppointmentSlots = (): SlotsAndUnavailabilityWithRefetch => {
  const [appointmentSlots, setAppointmentSlots] = useState<
    SlotsAndUnavailability
  >();

  const fetchSlots = async () => {
    fetchSlotsAndDayMessages().then((res) => {
      const slotsAndDayMessages: SlotsAndUnavailability = {
        availableSlots: res.availableSlots,
        unavailabilityMessages: res.unavailabilityMessages.map((dayMsg) => ({
          day: new Date(Date.parse(dayMsg.day)),
          message: dayMsg.message,
        })),
      };

      setAppointmentSlots(slotsAndDayMessages);
      trackEvent(getSnowplowEvent(slotsAndDayMessages));
    });
  };

  useEffect(() => {
    fetchSlots();
  }, []);

  return { data: appointmentSlots, refetch: fetchSlots };
};

async function fetchPracticeSettings() {
  return await getPracticeSettings();
}

export const usePracticeSettings = (authenticated: boolean | undefined) => {
  const [practiceSettings, setPracticeSettings] = useState<
    PracticeSettingsProps
  >({
    isAppointmentListEnabled: false,
    isChildBookingEnabled: false,
    isLiviPractice: false,
    partnership: 'UNKNOWN',
  });

  useEffect(() => {
    fetchPracticeSettings().then((res) => {
      setPracticeSettings({
        isAppointmentListEnabled: res.appointmentListEnabled,
        isChildBookingEnabled: res.childBookingEnabled,
        isLiviPractice: res.isLiviPractice,
        partnership: res.partnership,
      });
    });
  }, [authenticated]);
  return practiceSettings;
};
