import React, { ReactElement } from 'react';
import { AlertProps } from './types';
import cn from 'classnames';
import styles from './Alert.module.css';

const Alert = ({ children, className }: AlertProps): ReactElement => {
  return (
    <div role="alert" className={cn(styles.container, className)}>
      {children}
    </div>
  );
};

export default Alert;
