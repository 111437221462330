import React from 'react';
import FormattedMessage from '../../../widget-ui-toolkit/FormattedMessage';
import { isProdEnvironment } from '../../../utils/environment';
import Checkbox from '../../../widget-ui-toolkit/Checkbox';
import { ConfirmAdultPatientTypeViewProps } from './types';

const LOCALIZE_PREFIX = 'widget.booking.main';

const getConfirmAgeString = (
  isLiviPractice: boolean,
  isAccompanied?: boolean
): string => {
  if (isLiviPractice && !isProdEnvironment()) {
    if (isAccompanied) {
      return `${LOCALIZE_PREFIX}.confirm_that_patient_is_over_1_label`;
    }
    return `${LOCALIZE_PREFIX}.confirm_that_patient_is_over_13_label`;
  }
  return `${LOCALIZE_PREFIX}.confirm_that_patient_is_adult_label`;
};

const ConfirmAdultPatientTypeView = ({
  isLiviPractice,
  isAccompanied,
  defaultChecked,
  onChange,
}: ConfirmAdultPatientTypeViewProps) => {
  return (
    <>
      <Checkbox defaultChecked={defaultChecked} onClick={onChange}>
        <FormattedMessage
          id={getConfirmAgeString(isLiviPractice, isAccompanied)}
        />
      </Checkbox>
    </>
  );
};

export default ConfirmAdultPatientTypeView;
