import { AppRoutes } from '../../ContentViewRouter.types';

export enum Channel {
  Devel = 'devel',
  Beta = 'beta',
  Alpha = 'alpha',
  Production = 'production',
}

export type HeaderTitleProps = {
  title?: string;
  onClose: () => void;
};

export type HeaderProps = {
  onClose: () => void;
  route?: AppRoutes;
};

export enum HeaderTitles {
  Login = 'widget.login.header',
  AppointmentStatus = 'widget.booking.header_new',
  BookingFlow = 'widget.booking.header_new',
  Miscellaneous = 'widget.miscellaneous.header',
  NoTitle = '',
}
