import React, { useState } from 'react';
import Button from '../../widget-ui-toolkit/Button';
import styles from './ScrollContainer.module.css';
import { ReactComponent as ArrowUp } from '../../../media/ArrowUp.svg';

import { ScrollableProps } from './types';

const ScrollContainer = (props: ScrollableProps) => {
  const [scrollButtonVisible, setScrollButtonVisible] = useState(false);

  const handleScroll = (e: any) => {
    if (e.target.scrollTop > 100 && !scrollButtonVisible) {
      setScrollButtonVisible(true);
    } else if (e.target.scrollTop <= 100 && scrollButtonVisible) {
      setScrollButtonVisible(false);
    }
  };

  return (
    <div className={styles.scrollContainer} onScroll={handleScroll}>
      <div id="scrollReset" />
      {props.children}
      {scrollButtonVisible && (
        <div className={styles.scrollToTopButtonContainer}>
          <a href={props.href} className={styles.scrollToTopAnchor}>
            <Button className={styles.scrollToTopButton}>
              <ArrowUp className={styles.scrollToTopIcon} />
              Back to top
            </Button>
          </a>
        </div>
      )}
    </div>
  );
};

export default ScrollContainer;
