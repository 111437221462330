import React from 'react';
import { ReactElement } from 'react';
import cn from 'classnames';
import { PaginationDotsProps } from './types';
import styles from './SingleStepPagination.module.css';

const PaginationDots = ({
  selectedDot,
  numDots,
}: PaginationDotsProps): ReactElement => {
  return (
    <div className={styles.dotWrapper}>
      {[...new Array(numDots).keys()].map((x) => (
        <i
          className={cn(styles.dot, x === selectedDot && styles.selected)}
          key={x}
        ></i>
      ))}
    </div>
  );
};

export default PaginationDots;
