export enum ValidationErrors {
  NoError = '',
  PhoneNumberError = 'widget.booking.main.invalid_phone_number',
  NhsNumberError = 'widget.booking.main.invalid_nhs_number',
  SlotUnavailable = 'widget.booking.main.slot_unavailable_error',
  NameError = 'widget.booking.main.invalid_name',
  DateOfBirthError = 'widget.booking.main.invalid_date_of_birth',
  ChildDateOfBirthAgeLimitError = 'widget.booking.main.invalid_child_date_of_birth',
  AdultDateOfBirthAgeLimitError = 'widget.booking.main.invalid_adult_date_of_birth',
  DateOfBirthInFutureError = 'widget.booking.main.invalid_date_of_birth_in_future',
  GenderNotSelectedError = 'widget.booking.main.invalid_gender_not_selected',
  AddressError = 'widget.booking.main.invalid_address',
  PostcodeError = 'widget.booking.main.invalid_post_code',
  EmailError = 'widget.booking.main.invalid_email',
}
