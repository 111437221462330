import React, { ReactElement } from 'react';
import Button from '../../widget-ui-toolkit/Button';
import LinkButton from '../../widget-ui-toolkit/Button/LinkButton';
import { MiscellaneousProps } from './types';
import FormattedMessage from '../../widget-ui-toolkit/FormattedMessage';
import styles from './Miscellaneous.module.css';
import cn from 'classnames';
import { getVersion } from '../../utils/platformUtils';

const LOCALIZE_PREFIX = 'widget.miscellaneous';

const Miscellaneous = ({ onLogout }: MiscellaneousProps): ReactElement => {
  return (
    <div className={styles.container}>
      <h2>
        <FormattedMessage id={`${LOCALIZE_PREFIX}.help_centre.subheader`} />
      </h2>
      <p className={cn('soft', 'center', styles.liviContactInfo)}>
        <FormattedMessage id={`${LOCALIZE_PREFIX}.help_centre.info`} />
      </p>
      <LinkButton href="https://www.livi.co.uk/booking-tool-resources/">
        <FormattedMessage id={`${LOCALIZE_PREFIX}.help_centre.button`} />
      </LinkButton>
      <hr />
      <h2>
        <FormattedMessage id={`${LOCALIZE_PREFIX}.livi_support.subheader`} />
      </h2>
      <p className={cn('soft', 'center', styles.liviContactInfo)}>
        <FormattedMessage id={`${LOCALIZE_PREFIX}.livi_support.contact_info`} />
      </p>
      <strong className={styles.supportPhoneNumber}>
        <FormattedMessage id={`${LOCALIZE_PREFIX}.support_phone_number`} />
      </strong>
      <div className={styles.logoutButtonContainer}>
        <Button alert secondary onClick={onLogout}>
          <FormattedMessage id={`${LOCALIZE_PREFIX}.logout_button`} />
        </Button>
      </div>
      <p className={cn('soft', 'center')}>
        <FormattedMessage id={`${LOCALIZE_PREFIX}.version`} /> {getVersion()}
      </p>
    </div>
  );
};

export default Miscellaneous;
