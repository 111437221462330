import React, { useEffect, useState } from 'react';
import { AdultBookingViewProps } from './types';
import styles from './AdultBookingView.module.css';
import TextInput from '../../../widget-ui-toolkit/TextInput';
import { getAccompaniedLocalisePrefix } from '../../../utils/copy';
import { ValidationErrors } from '../../../utils/validation/types';
import { validateNhsNumber } from '../../../utils/validation/nhs-validation';
import { validatePhoneNumber } from '../../../utils/validation/phone-validation';

const LOCALIZE_PREFIX = 'widget.booking.main';

const AdultBookingView = ({
  booking,
  onChangeBooking,
  isLiviPractice,
  accompaniedOption,
  setValidationState,
}: AdultBookingViewProps) => {
  const [isValidNhsNumber, setIsValidNhsNumber] = useState<
    ValidationErrors | undefined
  >(
    booking.patientNhsNumber
      ? validateNhsNumber(booking.patientNhsNumber)
      : undefined
  );
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState<
    ValidationErrors | undefined
  >(booking.phoneNumber ? validatePhoneNumber(booking.phoneNumber) : undefined);

  function nhsValidation() {
    setIsValidNhsNumber(validateNhsNumber(booking.patientNhsNumber));
  }

  function phoneNumberValidation() {
    setIsValidPhoneNumber(validatePhoneNumber(booking.phoneNumber));
  }

  useEffect(() => {
    const isNhsValid = validateNhsNumber(booking.patientNhsNumber);
    if (isNhsValid === ValidationErrors.NoError) {
      setIsValidNhsNumber(isNhsValid);
    } else {
      setIsValidNhsNumber(undefined);
    }
  }, [booking.patientNhsNumber]);

  useEffect(() => {
    const isPhoneNumberValid = validatePhoneNumber(booking.phoneNumber);
    if (isPhoneNumberValid === ValidationErrors.NoError) {
      setIsValidPhoneNumber(isPhoneNumberValid);
    } else {
      setIsValidPhoneNumber(undefined);
    }
  }, [booking.phoneNumber]);

  useEffect(() => {
    setValidationState(
      isValidPhoneNumber === ValidationErrors.NoError &&
        isValidNhsNumber === ValidationErrors.NoError &&
        Boolean(booking.optionalNotes)
    );
  }, [
    isValidNhsNumber,
    isValidPhoneNumber,
    booking.optionChecked,
    booking.optionalNotes,
  ]);

  return (
    <>
      <div className={styles.inputWrapper}>
        <TextInput
          value={booking.patientNhsNumber}
          validationError={isValidNhsNumber}
          onChange={(e) =>
            onChangeBooking({ ...booking, patientNhsNumber: e.target.value })
          }
          onBlur={nhsValidation}
          label={`${LOCALIZE_PREFIX}.patient_nhs_number_label`}
          id="nhsNumber"
        />
        <TextInput
          value={booking.phoneNumber}
          validationError={isValidPhoneNumber}
          onChange={(e) =>
            onChangeBooking({ ...booking, phoneNumber: e.target.value })
          }
          onBlur={phoneNumberValidation}
          label={`${getAccompaniedLocalisePrefix(
            LOCALIZE_PREFIX,
            !!accompaniedOption.value
          )}.patient_mobile_number_label`}
          id="mobile"
        />
        <TextInput
          value={booking.optionalNotes}
          onChange={(e) =>
            onChangeBooking({ ...booking, optionalNotes: e.target.value })
          }
          label={`${LOCALIZE_PREFIX}.appointment_reason_label_mandatory`}
          id="reason"
          inputType="textarea"
        />
      </div>
    </>
  );
};

export default AdultBookingView;
