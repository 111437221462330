import {
  newTracker,
  StructuredEvent,
  trackStructEvent,
  setUserId,
  BrowserTracker,
} from '@snowplow/browser-tracker';
import {
  getSnowplowCollectorEndpoint,
  isLocalEnvironment,
} from './environment';

const TRACKER_NAMESPACE = 'booking-tool';
const COLLECTOR_ENDPOINT = getSnowplowCollectorEndpoint();
const APP_ID = 'booking-tool-widget';

export const initTracker = (): BrowserTracker =>
  newTracker(TRACKER_NAMESPACE, COLLECTOR_ENDPOINT, {
    appId: APP_ID,
    cookieSameSite: 'Lax',
  });

export const trackEvent = (
  payload: Omit<StructuredEvent, 'category'>
): void => {
  if (isLocalEnvironment()) {
    console.info('Tracking event: ', JSON.stringify(payload));
  } else {
    trackStructEvent({ category: 'booking-tool', ...payload });
  }
};

export const setTrackerUserId = (userId: string): void => {
  setUserId(userId, [TRACKER_NAMESPACE]);
};
