import { localize } from '../../widget-ui-toolkit/FormattedMessage/localize';
import { Locale } from '../../widget-ui-toolkit/FormattedMessage/types';

const ACCOMPANIED_LOCALIZE_PREFIX = 'widget.accompanied';
const PATIENT_TYPE_LOCALIZE_PREFIX = 'widget.select_patient_type';

export const isAccompaniedOptions = [
  {
    id: 0,
    testId: 'unaccompanied_radio_option',
    label: localize(
      `${ACCOMPANIED_LOCALIZE_PREFIX}.unaccompanied_radio_option`,
      Locale.en
    ),
    value: false,
  },
  {
    id: 1,
    testId: 'accompanied_radio_option',
    label: localize(
      `${ACCOMPANIED_LOCALIZE_PREFIX}.accompanied_radio_option`,
      Locale.en
    ),
    value: true,
  },
];

export const selectPatientTypeOptions = [
  {
    id: 0,
    testId: 'adult_radio_option',
    label: localize(
      `${PATIENT_TYPE_LOCALIZE_PREFIX}.adult_radio_option`,
      Locale.en
    ),
    value: false,
  },
  {
    id: 1,
    testId: 'child_radio_option',
    label: localize(
      `${PATIENT_TYPE_LOCALIZE_PREFIX}.child_radio_option`,
      Locale.en
    ),
    value: true,
  },
];
