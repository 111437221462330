import { ValidationErrors } from './types';
import moment from 'moment';

export const validateChildDateOfBirth = (
  dateOfBirth?: string
): ValidationErrors => {
  const preValidation = validateDateOfBirth(dateOfBirth);
  if (preValidation !== ValidationErrors.NoError) {
    return preValidation;
  }

  const momentDateOfBirth = moment(dateOfBirth, 'DD-MM-YYYY');
  const age = getAge(momentDateOfBirth);
  if (age > 15) {
    return ValidationErrors.ChildDateOfBirthAgeLimitError;
  }

  return ValidationErrors.NoError;
};

export const validateAdultDateOfBirth = (
  dateOfBirth?: string
): ValidationErrors => {
  const preValidation = validateDateOfBirth(dateOfBirth);
  if (preValidation !== ValidationErrors.NoError) {
    return preValidation;
  }

  const momentDateOfBirth = moment(dateOfBirth, 'DD-MM-YYYY');
  const age = getAge(momentDateOfBirth);
  if (age < 16) {
    return ValidationErrors.AdultDateOfBirthAgeLimitError;
  }

  return ValidationErrors.NoError;
};

const getAge = (dateOfBirth: moment.Moment): number => {
  const today = moment();
  return today.diff(dateOfBirth, 'years');
};

export const validateDateOfBirth = (dateOfBirth?: string): ValidationErrors => {
  if (!dateOfBirth) return ValidationErrors.DateOfBirthError;
  if (
    !dateOfBirth.match(
      '^(?:(?:31(-)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(-)(?:0?[1,3-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$|^(?:29(-|)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\\d|2[0-8])(-)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)\\d{2})$'
    )
  )
    return ValidationErrors.DateOfBirthError;

  const momentDateOfBirth = moment(dateOfBirth, 'DD-MM-YYYY');
  if (momentDateOfBirth.isAfter(moment())) {
    return ValidationErrors.DateOfBirthInFutureError;
  }

  return ValidationErrors.NoError;
};
