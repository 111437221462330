import React from 'react';
import styles from './BookingCard.module.css';
import { BookingCardProps } from './types';
import BookingStatusCard from '../BookingStatusCard';

const BookingCard = (props: BookingCardProps) => {
  const getText = (
    value: String,
    highlightText?: string,
    isPhoneNumber?: boolean
  ) => {
    if (highlightText !== null) {
      if (isPhoneNumber) {
        if (highlightText?.charAt(0) === '0' && highlightText?.length > 1) {
          highlightText = highlightText.substring(1);
        }
      }
      const parts = value.split(new RegExp(`(${highlightText})`, 'gi'));
      return parts.map((part, index) => (
        <React.Fragment key={index}>
          {part === highlightText! ? <b>{part}</b> : part}
        </React.Fragment>
      ));
    }
    return value;
  };

  return (
    <>
      <div className={styles.bookingCard}>
        {props.appointmentStatus === 'CANCELLED' && (
          <BookingStatusCard
            appointmentStatus={props.appointmentStatus}
            partnership={props.partnership}
          />
        )}
        <p className={styles.bookingCardMobileNumber}>
          Mobile No: {getText(props.mobileNumber, props.highlightText, true)}
        </p>
        <p className={styles.bookingCardNhsAndTime}>
          <span className={styles.bookingCardAppointmentTime}>
            {(props.appointmentDateTime.getHours() < 10 ? '0' : '') +
              props.appointmentDateTime.getHours()}
            .
            {(props.appointmentDateTime.getMinutes() < 10 ? '0' : '') +
              props.appointmentDateTime.getMinutes()}
          </span>
          <span className={styles.bookingCardNhsAndTimeSeparator}> | </span>
          {
            <span className={styles.bookingCardNhsNumber}>
              NHS No:{' '}
              {getText(
                props.childNhsNumber !== undefined
                  ? props.childNhsNumber
                  : props.nhsNumber!,
                props.highlightText
              )}
            </span>
          }
          {props.childNhsNumber !== undefined && (
            <span className={styles.childChip}>Child</span>
          )}
        </p>
      </div>
      {props.shouldRenderBreak && <hr className={styles.cardSeparator} />}
    </>
  );
};

export default BookingCard;
