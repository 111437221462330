import React, { ReactElement } from 'react';
import { CheckboxProps } from './types';
import cn from 'classnames';
import styles from './Checkbox.module.css';

const Checkbox = ({
  children,
  defaultChecked,
  onClick,
}: CheckboxProps): ReactElement => {
  return (
    <div className={cn('content', styles.checkboxWrapper)}>
      <label className={cn('container', styles.checkboxInput)}>
        <input
          defaultChecked={defaultChecked}
          type="checkbox"
          onClick={onClick}
        />
        <span className={styles.checkmark}></span>
        {children}
      </label>
    </div>
  );
};

export default Checkbox;
