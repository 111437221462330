import React, { ReactElement, useContext, useState } from 'react';
import Button from '../../widget-ui-toolkit/Button';
import { ReactComponent as LeftChevron } from '../../../media/LeftChevron.svg';
import { ReactComponent as RightChevron } from '../../../media/RightChevron.svg';
import { SizeContext } from '../../utils/SizeContext';
import { WEEK_DAYS, MAX_PAGES } from './constants';
import { WeekInputProps } from './types';
import WeekDayInput from './WeekDayInput';
import { getMonday } from '../../utils/date';
import styles from './WeekInput.module.css';

const DAY_IN_MS = 1000 * 60 * 60 * 24;

const WeekInput = ({
  onChange,
  value,
  maxDaysEnabled,
}: WeekInputProps): ReactElement => {
  const bookingHorizon = new Date().setDate(
    new Date().getDate() + maxDaysEnabled
  );

  const diffMillis = Math.abs(
    getMonday(new Date()).getTime() - value.getTime()
  );
  const diffDays = Math.floor(diffMillis / DAY_IN_MS);
  const [page, setPage] = useState(Math.floor(diffDays / 6));

  const toDisplay = value || new Date();
  const date = toDisplay.getDate();
  const day = (toDisplay.getDay() + 6) % 7;
  const now = new Date();
  const startDate = date - day;
  const onSizeUpdate = useContext(SizeContext);

  const onChangePage = (newPage: number) => {
    const newDate = Math.max(now.getDate(), startDate + (newPage - page) * 7);
    onChange(new Date(new Date().setDate(newDate)));
    setPage(newPage);
    onSizeUpdate();
  };
  return (
    <div className={styles.weekInput}>
      <Button
        className={styles.leftChevron}
        onClick={() => onChangePage(page - 1)}
        disabled={page === 0}
        transparent
        title="Left"
      >
        <LeftChevron className={styles.chevron} />
      </Button>
      {WEEK_DAYS.map((x, i) => {
        const wDate = getMonday(new Date());
        wDate.setDate(wDate.getDate() + page * 7 + i);

        // We need to set end of day for both these dates, since otherwise when comparing them
        // will compare start of day with a later time, which disables the last day of the
        // selectable period
        const wDateTime = new Date(wDate.setHours(23, 59, 59, 999)).getTime();
        const bookingHorizonEndOfDay = new Date(
          new Date(bookingHorizon).setHours(23, 59, 59, 999)
        ).getTime();
        return (
          <WeekDayInput
            onClick={() => {
              onChange(wDate);
              onSizeUpdate();
            }}
            key={x}
            dayOfWeek={x}
            disabled={wDate < now || wDateTime > bookingHorizonEndOfDay}
            selected={value.toDateString() === wDate.toDateString()}
            dateOfMonth={wDate.getDate()}
            isToday={wDate.getDate() === now.getDate()}
          />
        );
      })}
      <Button
        className={styles.rightChevron}
        onClick={() => onChangePage(page + 1)}
        disabled={page + 1 === MAX_PAGES}
        transparent
        title="Right"
      >
        <RightChevron className={styles.chevron} />
      </Button>
    </div>
  );
};

export default WeekInput;
