import React from 'react';
import { HeaderTitleProps } from './types';
import { ReactComponent as Cross } from '../../../media/Cross.svg';
import FormattedMessage from '../../widget-ui-toolkit/FormattedMessage';
import styles from './HeaderTitle.module.css';

const HeaderTitle = ({ title, onClose }: HeaderTitleProps) => {
  return (
    <div className={styles.titleWrapper}>
      {title && (
        <div className={styles.title}>
          <span className={styles.closeIcon} onClick={onClose} title="Close">
            <Cross />
          </span>
          <h2 className={styles.titleText}>
            <FormattedMessage id={title} />
          </h2>
        </div>
      )}
    </div>
  );
};

export default HeaderTitle;
