import { BookingState } from '../views/BookingFlow/types';
import {
  LANG_URL,
  BOOKED_APPOINTMENTS_URL,
  BOOKING_SLOTS_URL,
  BOOK_MEETING_URL,
  PRACTICE_SETTINGS_URL,
} from './constants';
import request from './request';
import {
  BookingResponse,
  BookingSlotsResponse,
  PracticeSettingsResponse,
  BookedAppointmentsResponse,
} from './types';

export async function getLang(): Promise<object> {
  return request<object>(LANG_URL, {}, false);
}

export async function getBookingSlots(
  startTime: number,
  endTime: number
): Promise<BookingSlotsResponse> {
  return request<BookingSlotsResponse>(
    `${BOOKING_SLOTS_URL}?start_time=${startTime}&end_time=${endTime}`,
    {
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem('jwtToken')}`,
      },
    }
  );
}

export async function bookMeeting(
  booking: BookingState
): Promise<BookingResponse> {
  const body = {
    phone_number: booking.phoneNumber,
    nhs_number: booking.patientNhsNumber,
    gender: booking.patientGender,
    meeting_time: booking.timeSlot?.toISOString().replace('.000', ''),
    appointment_reason: booking.optionalNotes,
    is_accompanied: booking.isAccompanied,
    is_child: booking.isChild,
    patient_full_name: booking.patientName,
    patient_date_of_birth: booking.patientDateOfBirth,
    legal_guardian_nhs_number: booking.legalGuradianNhsNumber,
  };

  return request<BookingResponse>(`${BOOK_MEETING_URL}`, {
    method: 'POST',
    body,
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem('jwtToken')}`,
    },
  });
}

export async function getPracticeSettings(): Promise<PracticeSettingsResponse> {
  if (window.sessionStorage.getItem('jwtToken') == null) {
    return Promise.resolve({
      appointmentListEnabled: false,
      childBookingEnabled: false,
      isLiviPractice: true,
      partnership: 'UNKNOWN',
    });
  }

  return request<PracticeSettingsResponse>(`${PRACTICE_SETTINGS_URL}`, {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem('jwtToken')}`,
    },
  });
}

export async function getBookedAppointments(): Promise<
  BookedAppointmentsResponse
> {
  return request<BookedAppointmentsResponse>(`${BOOKED_APPOINTMENTS_URL}`, {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem('jwtToken')}`,
    },
  });
}
