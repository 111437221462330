import React, { ReactElement, useState, useContext, useEffect } from 'react';
import { LoginFlowRouterProps, LoginViews } from './LoginFlow/types';
import FailView from './LoginFlow/FailView';
import SuccessView from './LoginFlow/SuccessView';
import { SizeContext } from '../utils/SizeContext';
import { AppRoutes } from '../ContentViewRouter.types';
import { trackEvent, setTrackerUserId } from '../utils/eventTracking';
import {
  openNhsAuth,
  closeElectronNhsAuth,
  addElectronNhsAuthCallbacks,
  removeElectronNhsAuthCallbacks,
} from '../utils/platformUtils';

const LoginFlowRouter = ({
  onChangeRoute,
  onSetAuthenticated,
}: LoginFlowRouterProps): ReactElement => {
  const [view, setView] = useState<LoginViews>(LoginViews.Main);
  const onSizeUpdate = useContext(SizeContext);

  const onRetry = () => {
    onSetAuthenticated(undefined);
    setView(LoginViews.Main);
    openNhsAuth();
  };

  useEffect(() => {
    function tokenCallback(event: any, arg: any) {
      window.sessionStorage.setItem('jwtToken', arg.jwtToken);
      window.localStorage.setItem('userId', arg.userId);
      setTrackerUserId(arg.userId);
      onSetAuthenticated(true);
      setView(LoginViews.Success);
      closeElectronNhsAuth();
      onSizeUpdate();
    }

    function errorCallback(event: any, arg: any) {
      if (Boolean(arg)) {
        setView(LoginViews.Fail);
        onSetAuthenticated(false);
        trackEvent({ action: 'unauthorised-login-error-displayed' });
        closeElectronNhsAuth();
        onSizeUpdate();
      }
    }
    addElectronNhsAuthCallbacks(tokenCallback, errorCallback);
    return () => {
      removeElectronNhsAuthCallbacks(tokenCallback, errorCallback);
      setView(LoginViews.Main);
    };
  }, [onSetAuthenticated, onSizeUpdate]);

  useEffect(() => {
    if (view === LoginViews.Main) {
      openNhsAuth();
    }
  }, [view]);

  return (
    <>
      {view === LoginViews.Main && <div data-testid="nhs-auth" />}
      {view === LoginViews.Fail && <FailView onRetry={onRetry} />}
      {view === LoginViews.Success && (
        <SuccessView
          onBookAppointment={() => onChangeRoute(AppRoutes.BookingFlow)}
        />
      )}
    </>
  );
};

export default LoginFlowRouter;
