import { DailyUnavailabilityMessage } from '../TimeScheduleInput/types';

export const extractUnavailabilityMessage = (
  unavailabilityMessages: DailyUnavailabilityMessage[],
  today: Date
): DailyUnavailabilityMessage | null => {
  const dayMessageArray: DailyUnavailabilityMessage[] = unavailabilityMessages?.filter(
    (dayMsg) => {
      const todayString = `${today.getFullYear()}-${today.getMonth()}-${today.getDate()}`;
      const dayMessageDayString = `${dayMsg.day.getFullYear()}-${dayMsg.day.getMonth()}-${dayMsg.day.getDate()}`;
      return dayMessageDayString === todayString;
    }
  );
  return dayMessageArray?.length > 0 ? dayMessageArray[0] : null;
};
