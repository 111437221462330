export const getAccompaniedLocalisePrefix = (
  localise_prefix: string,
  isAccompanied: boolean
): string =>
  isAccompanied ? `${localise_prefix}.accompanied_flow` : localise_prefix;

export const getDayStringFromDate = (
  date: Date,
  locale: string = 'en-Gb'
): string => date.toLocaleDateString(locale, { weekday: 'long' });

export const capFirstLetter = (str: string): string => {
  if (!str.length) return str;
  return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
};
