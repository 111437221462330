import React from 'react';
import BookingCard from '../BookingCard';
import styles from './AppointmentStatusDayCard.module.css';
import { AppointmentStatusDayCardProps } from './types';

const AppointmentStatusDayCard = (props: AppointmentStatusDayCardProps) => {
  return (
    <div className={styles.daySlot}>
      <div className={styles.daySlotTitleCard}>
        <div className={styles.daySlotTitle}>
          <span className={styles.daySlotDay}>{props.dayOfTheWeek}</span>
          <span> - {props.date}</span>
        </div>
      </div>
      <div className={styles.bookingCardsContainer}>
        {props.bookings.map(
          (
            { bookingUser, bookingChild, dateString, meetingStartTime, status },
            index
          ) => {
            return (
              <BookingCard
                appointmentDateTime={new Date(meetingStartTime)}
                mobileNumber={bookingUser.phoneNumber}
                nhsNumber={
                  bookingUser.nhsNumber !== undefined
                    ? bookingUser.nhsNumber
                    : undefined
                }
                childNhsNumber={
                  bookingChild !== undefined
                    ? bookingChild.nhsNumber
                    : undefined
                }
                appointmentStatus={status}
                shouldRenderBreak={
                  index !== props.bookings.length - 1 || props.isLastDay
                }
                highlightText={props.highlightText}
                partnership={props.partnership}
              />
            );
          }
        )}
      </div>
    </div>
  );
};

export default AppointmentStatusDayCard;
