import React from 'react';
import { useState, ReactElement } from 'react';
import Button from '../../../widget-ui-toolkit/Button';
import DateTimeScheduleInput from '../../../components/DateTimeScheduleInput/DateTimeScheduleInput';
import { CalendarViewProps } from './types';
import { trackEvent } from '../../../utils/eventTracking';
import FormattedMessage from '../../../widget-ui-toolkit/FormattedMessage';
import styles from './CalendarView.module.css';

const LOCALIZE_PREFIX = 'widget.booking.calendar';

const CalendarView = ({
  onSubmit,
  onCancel,
  schedule,
  appointmentDateTime,
  bookingHorizonDays,
}: CalendarViewProps): ReactElement => {
  const [date, setDate] = useState(appointmentDateTime);
  const [isValidSlotSelected, setIsValidSlotSelected] = useState(true);

  const updateBooking = () => {
    onSubmit(date);
    trackEvent({ action: 'new-date-confirmed', label: date.toISOString() });
  };

  const handleChangeDate = (date: Date, isValidSlot: boolean) => {
    setDate(date);
    setIsValidSlotSelected(isValidSlot);
  };

  return (
    <>
      <DateTimeScheduleInput
        slots={schedule}
        onChange={handleChangeDate}
        value={date}
        maxDaysEnabled={bookingHorizonDays}
      />
      <div className={styles.buttonWrapper}>
        <Button secondary onClick={onCancel}>
          <FormattedMessage id={`${LOCALIZE_PREFIX}.cancel_date_button`} />
        </Button>
        <div className={styles.buttonDivider} />
        <Button primary onClick={updateBooking} disabled={!isValidSlotSelected}>
          <FormattedMessage id={`${LOCALIZE_PREFIX}.confirm_date_button`} />
        </Button>
      </div>
    </>
  );
};

export default CalendarView;
