import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import { LoaderSizeMarginProps } from 'react-spinners/interfaces';

const Loader = (props: LoaderSizeMarginProps) => {
  return (
    <BeatLoader color={'var(--default-font-color)'} {...props}></BeatLoader>
  );
};

export default Loader;
