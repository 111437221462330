import React, { ReactElement } from 'react';
import Button from '../../widget-ui-toolkit/Button';
import { WeekDayInputProps } from './types';
import cn from 'classnames';
import FormattedMessage from '../../widget-ui-toolkit/FormattedMessage';
import styles from './WeekInput.module.css';

const LOCALIZE_PREFIX = 'widget.booking.calendar';

const WeekDayInput = ({
  dateOfMonth,
  dayOfWeek,
  selected,
  disabled,
  onClick,
  isToday,
}: WeekDayInputProps): ReactElement => {
  return (
    <div
      className={cn(
        styles.weekDay,
        selected && styles.selected,
        isToday && styles.today
      )}
    >
      <small className={styles.weekDayLabel}>
        <FormattedMessage id={`${LOCALIZE_PREFIX}.${dayOfWeek}`} />
      </small>
      <Button
        onClick={onClick}
        disabled={disabled}
        transparent
        primary={selected}
      >
        {dateOfMonth}
      </Button>
    </div>
  );
};

export default WeekDayInput;
