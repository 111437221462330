import React, { ReactElement } from 'react';
import { ReactComponent as CircledCheck } from '../../../media/CircledCheck.svg';
import Button from '../../widget-ui-toolkit/Button';
import { SuccessViewProps } from './types';
import FormattedMessage from '../../widget-ui-toolkit/FormattedMessage';
import styles from './SuccessView.module.css';
import cn from 'classnames';

const LOCALIZE_PREFIX = 'widget.login.success';

const SuccessView = ({ onBookAppointment }: SuccessViewProps): ReactElement => {
  return (
    <div className={styles.successViewWrapper}>
      <CircledCheck className={styles.successIcon} />
      <h2 className={styles.successHeader}>
        <FormattedMessage id={`${LOCALIZE_PREFIX}.subheader`} />
      </h2>
      <p className={cn('soft', 'center', styles.successInfo)}>
        <FormattedMessage id={`${LOCALIZE_PREFIX}.success_message`} />
      </p>
      <Button onClick={onBookAppointment}>
        <FormattedMessage id={`${LOCALIZE_PREFIX}.book_appointment_button`} />
      </Button>
    </div>
  );
};

export default SuccessView;
