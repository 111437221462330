import { RadioOption } from '../../widget-ui-toolkit/RadioButton/types';

export enum BookingFlowViews {
  Main,
  Calendar,
  FullyBooked,
  Loading,
  Confirmation,
}

export type CalendarViewProps = {
  nhsPatientSelectorEnabled?: boolean;
  nhsAdultPatientViewEnabled?: boolean;
  nhsChildPatientViewEnabled?: boolean;

  nhsConfirmPatientAdultViewEnabled?: boolean;
};

export type BookingState = {
  timeSlot: Date | undefined;

  patientNhsNumber?: string;
  patientName?: string;
  patientDateOfBirth?: string;
  patientGender?: string;
  phoneNumber: string;

  optionalNotes?: string;
  optionChecked: boolean;

  isAccompanied?: boolean;
  isChild?: boolean;
  legalGuradianNhsNumber?: string;
  legalGuardianCheckboxChecked: boolean;
};

export type MainViewProps = {
  booking: BookingState;
  onChangeBooking: (booking: BookingState) => void;
  onSelectNewAppointmentDateTime: () => void;
  appointmentDateTime: Date;
  onBookAppointment: () => void;
  isSlotUnavailable: boolean;
  isLiviPractice: boolean;
  partnership: string;
  accompaniedOption: RadioOption;
  setAccompaniedOption: (value: RadioOption) => void;
  isChildBookingEnabled: boolean;
  patientTypeOption: RadioOption;
  setPatientTypeOption: (value: RadioOption) => void;
};

export type PracticeSettingsProps = {
  isAppointmentListEnabled: boolean;
  isChildBookingEnabled: boolean;
  isLiviPractice: boolean;
  partnership: string;
};

export enum MainSubViews {
  Accompanied,
  Booking,
  null,
}
