import React, { ReactElement } from 'react';
import cn from 'classnames';
import styles from './RadioButton.module.css';
import { RadioButtonProps } from './types';

const RadioButton = ({
  selected,
  setSelected,
  radioOptions,
  renderAsCheckbox = false,
}: RadioButtonProps): ReactElement => {
  const radioStyle = renderAsCheckbox ? styles.checkmark : styles.customRadio;

  return (
    <div>
      {radioOptions.map((type, i) => (
        <div key={i} className={cn('content', styles.radioButtonWrapper)}>
          {
            <label className={cn('container', styles.radioButtonInput)}>
              <input
                type="radio"
                data-testid={radioOptions[i].testId}
                key={type.id}
                checked={selected?.id === i}
                onChange={() => {
                  setSelected(radioOptions[i]);
                }}
              />
              <span className={radioStyle}></span>
              {type.label}
            </label>
          }
        </div>
      ))}
    </div>
  );
};

export default RadioButton;
