import React from 'react';
import styles from './AppointmentStatusSummaryMessage.module.css';
import { AppointmentStatusSummaryMessageProps } from './types';

const AppointmentStatusSummaryMessage = (
  props: AppointmentStatusSummaryMessageProps
) => {
  return (
    <>
      <hr className={styles.cardSeparator} />
      <div className={styles.bookingsSummaryMessageContainer}>
        <p className={styles.bookingsSummaryMessage}>{props.message}</p>
      </div>
    </>
  );
};

export default AppointmentStatusSummaryMessage;
