import React, { useState, useContext, ReactElement, useEffect } from 'react';
import { SizeContext } from '../utils/SizeContext';
import MainView from './BookingFlow/MainView';
import CalendarView from './BookingFlow/Calendar/CalendarView';
import FullyBookedView from './BookingFlow/FullyBooked/FullyBookedView';
import AdultConfirmationView from './BookingFlow/AdultBooking/AdultConfirmationView';
import { BookingFlowViews, BookingState } from './BookingFlow/types';
import { useAppointmentSlots, usePracticeSettings } from './BookingFlow/hooks';
import LoadingView from '../components/Loading/LoadingView';
import { bookMeeting } from '../api/api';
import { useErrorHandler } from './Error/ErrorBoundary';
import { trackEvent } from '../utils/eventTracking';
import { BOOKING_HORIZON_DAYS } from '../constants';
import { RadioOption } from '../widget-ui-toolkit/RadioButton/types';
import ChildConfirmationView from './BookingFlow/ChildBooking/ChildConfirmationView';

const LOCALIZE_PREFIX = 'widget.booking.loading';

type BookingFlowProps = { onCloseContentView: () => void };

const BookingFlow = ({
  onCloseContentView,
}: BookingFlowProps): ReactElement => {
  const practiceSettings = usePracticeSettings(true);
  const { data, refetch } = useAppointmentSlots();
  const [appointmentDateTime, setAppointmentDateTime] = useState<Date>();
  const [currentView, setCurrentView] = useState<BookingFlowViews | undefined>(
    BookingFlowViews.Loading
  );
  const [isSlotUnavailable, setSlotUnavailable] = useState(false);
  const [booking, setBooking] = useState<BookingState>({
    timeSlot: new Date(),
    phoneNumber: '',
    optionChecked: false,
    legalGuardianCheckboxChecked: false,
  });
  const [accompaniedOption, setAccompaniedOption] = useState<RadioOption>({
    id: 0,
    label: '',
    value: false,
  });

  const [patientTypeOption, setPatientTypeOption] = useState<RadioOption>({
    id: -1,
    label: '',
    value: undefined,
  });

  const onSizeUpdate = useContext(SizeContext);
  const handleError = useErrorHandler();

  function changeView(view: BookingFlowViews) {
    setCurrentView(view);
    onSizeUpdate();
  }

  const updateBookingTime = (newDate: Date) => {
    setAppointmentDateTime(newDate);
    changeView(BookingFlowViews.Main);
  };

  const handleClose = () => {
    onCloseContentView();
    setCurrentView(undefined);
    setBooking({} as BookingState);
  };

  const bookAppointment = async () => {
    setCurrentView(BookingFlowViews.Loading);
    try {
      const response = await bookMeeting(booking);
      if (response) {
        changeView(BookingFlowViews.Confirmation);
      }
    } catch (error: any) {
      if (error.status === 400) {
        setSlotUnavailable(true);
        trackEvent({ action: 'slot-taken-error-displayed' });
        changeView(BookingFlowViews.Main);
      } else {
        handleError(error);
      }
    }
  };

  const handleSelectNewAppointmentDateTime = async () => {
    if (isSlotUnavailable) {
      await refetch();
    }
    changeView(BookingFlowViews.Calendar);
    setSlotUnavailable(false);
  };

  useEffect(() => {
    if (data && data?.availableSlots && !data.availableSlots.length) {
      setCurrentView(BookingFlowViews.FullyBooked);
    } else if (data) {
      setAppointmentDateTime(
        data.availableSlots.find((s) => !s.disabled)?.date
      );
    }
    onSizeUpdate();
  }, [data, onSizeUpdate]);

  useEffect(() => {
    if (data && data.availableSlots.length) {
      changeView(BookingFlowViews.Main);
    }
    setBooking({ ...booking, timeSlot: appointmentDateTime });
    onSizeUpdate();
  }, [appointmentDateTime]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setBooking({ ...booking, isAccompanied: accompaniedOption.value });
  }, [accompaniedOption.value]);

  useEffect(() => {
    setBooking({ ...booking, isChild: patientTypeOption.value });
  }, [patientTypeOption.value]);

  return (
    <>
      {currentView === BookingFlowViews.Loading && (
        <LoadingView
          message={
            booking.optionChecked ? '' : `${LOCALIZE_PREFIX}.loading_message`
          }
        />
      )}
      {currentView === BookingFlowViews.FullyBooked && <FullyBookedView />}
      {currentView === BookingFlowViews.Main && appointmentDateTime && (
        <MainView
          booking={booking}
          onChangeBooking={setBooking}
          appointmentDateTime={appointmentDateTime}
          onSelectNewAppointmentDateTime={handleSelectNewAppointmentDateTime}
          onBookAppointment={bookAppointment}
          isSlotUnavailable={isSlotUnavailable}
          isLiviPractice={practiceSettings.isLiviPractice}
          partnership={practiceSettings.partnership}
          accompaniedOption={accompaniedOption}
          setAccompaniedOption={setAccompaniedOption}
          isChildBookingEnabled={
            !practiceSettings.isLiviPractice &&
            practiceSettings.isChildBookingEnabled
          }
          patientTypeOption={patientTypeOption}
          setPatientTypeOption={setPatientTypeOption}
        />
      )}
      {currentView === BookingFlowViews.Calendar &&
        appointmentDateTime &&
        data && (
          <CalendarView
            schedule={data}
            appointmentDateTime={appointmentDateTime}
            onSubmit={updateBookingTime}
            onCancel={() => changeView(BookingFlowViews.Main)}
            bookingHorizonDays={BOOKING_HORIZON_DAYS}
          />
        )}
      {currentView === BookingFlowViews.Confirmation && !booking.isChild && (
        <AdultConfirmationView
          onClose={handleClose}
          booking={booking}
          isAccompanied={!!accompaniedOption.value}
        />
      )}
      {currentView === BookingFlowViews.Confirmation && booking.isChild && (
        <ChildConfirmationView onClose={handleClose} booking={booking} />
      )}
    </>
  );
};

export default BookingFlow;
