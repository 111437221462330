import React, { ReactElement } from 'react';
import TextInput from '../../../widget-ui-toolkit/TextInput';
import Button from '../../../widget-ui-toolkit/Button';
import formatDate from '../formatDate';
import FormattedMessage from '../../../widget-ui-toolkit/FormattedMessage';
import styles from './ChildConfirmationView.module.css';
import { ChildConfirmationViewProps } from './types';

const LOCALIZE_PREFIX = 'widget.booking.confirmation.child';

const ChildConfirmationView = ({
  onClose,
  booking,
}: ChildConfirmationViewProps): ReactElement => {
  return (
    <>
      <h1>
        <FormattedMessage id={`${LOCALIZE_PREFIX}.subheader`} />
      </h1>
      <h3>
        <FormattedMessage id={`${LOCALIZE_PREFIX}.text_message_sent`} />
      </h3>
      <div className={styles.bookingInfoWrapper}>
        <p className={styles.info}>
          <FormattedMessage id={`${LOCALIZE_PREFIX}.child_details_label`} />
        </p>
        <TextInput
          value={formatDate(booking.timeSlot)}
          validationError={undefined}
          disabled
          label={`${LOCALIZE_PREFIX}.appointment_details_label`}
          id="details"
        />
        <TextInput
          value={booking.patientNhsNumber}
          validationError={undefined}
          disabled
          label={`${LOCALIZE_PREFIX}.patient_nhs_number_label`}
          id="nhsNumber"
        />
        <TextInput
          value={booking.patientName}
          validationError={undefined}
          disabled
          label={`${LOCALIZE_PREFIX}.patient_name_label`}
          id="patientName"
        />
        <TextInput
          value={booking.patientDateOfBirth}
          validationError={undefined}
          disabled
          label={`${LOCALIZE_PREFIX}.patient_date_of_birth_label`}
          id="patientDateOfBirth"
        />
        <p className={styles.info}>
          <FormattedMessage
            id={`${LOCALIZE_PREFIX}.legal_guardian_details_label`}
          />
        </p>
        <TextInput
          value={booking.legalGuradianNhsNumber}
          validationError={undefined}
          disabled
          label={`${LOCALIZE_PREFIX}.legal_guardian_nhs_number_label`}
          id="legalGuradianNhsNumber"
        />
        <TextInput
          value={booking.phoneNumber}
          validationError={undefined}
          disabled
          label={`${LOCALIZE_PREFIX}.legal_guardian_mobile_number_label`}
          id="mobile"
        />
      </div>
      <div className={styles.confirmationInfo}>
        <p>
          <FormattedMessage
            id={`${LOCALIZE_PREFIX}.instruct_patient_to_download_app`}
          />
        </p>
      </div>
      <Button onClick={onClose}>
        <FormattedMessage id={`${LOCALIZE_PREFIX}.close_confirmation_button`} />
      </Button>
    </>
  );
};

export default ChildConfirmationView;
