import React, { ReactElement } from 'react';
import LiviLogo from '../../../media/LiviLogo.svg';
import HeaderTitle from './HeaderTitle';
import { HeaderProps, HeaderTitles } from './types';
import { AppRoutes } from '../../ContentViewRouter.types';
import cn from 'classnames';
import styles from './Header.module.css';

const getHeaderTitle = (route?: AppRoutes) => {
  if (route === AppRoutes.LoginFlow) return HeaderTitles.Login;
  if (route === AppRoutes.AppointmentStatus)
    return HeaderTitles.AppointmentStatus;
  if (route === AppRoutes.BookingFlow) return HeaderTitles.BookingFlow;
  if (route === AppRoutes.Miscellaneous) return HeaderTitles.Miscellaneous;
  return HeaderTitles.NoTitle;
};

const Header = ({ onClose, route }: HeaderProps): ReactElement => {
  const expanded = route !== undefined;
  return (
    <div className={cn(styles.wrapper, expanded && styles.expanded)}>
      {expanded && (
        <HeaderTitle onClose={onClose} title={getHeaderTitle(route)} />
      )}
      <div className={cn(styles.logoWrapper, expanded && styles.expanded)}>
        <img alt="livi logo" src={LiviLogo} className={styles.headerLogo} />
      </div>
    </div>
  );
};

export default Header;
