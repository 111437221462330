import React, { ReactElement } from 'react';
import { ReactComponent as Calendar } from '../../../media/MenuCalendar.svg';
import { ReactComponent as MagnifyingGlass } from '../../../media/MagnifyingGlass.svg';
import { ReactComponent as Power } from '../../../media/Power.svg';
import { ReactComponent as ThreeDots } from '../../../media/ThreeDots.svg';
import cn from 'classnames';
import { MenuProps } from './types';
import MenuItem from './MenuItem';
import styles from './Menu.module.css';
import { AppRoutes } from '../../ContentViewRouter.types';
import { usePracticeSettings } from '../../views/BookingFlow/hooks';

const Menu = ({ route, onClick, authenticated }: MenuProps): ReactElement => {
  const expanded = route !== undefined;
  const practiceSettings = usePracticeSettings(authenticated);

  return (
    <div className={cn(styles.wrapper, expanded && styles.expanded)}>
      <div className={cn(styles.menu, expanded && styles.menu)}>
        {authenticated ? (
          <>
            <MenuItem
              selected={route === AppRoutes.BookingFlow}
              onClick={() => onClick(AppRoutes.BookingFlow)}
              label="Book appointment"
              expanded={expanded}
            >
              <Calendar className={styles.sideMenuIcon} />
            </MenuItem>
            {practiceSettings.isAppointmentListEnabled && (
              <MenuItem
                selected={route === AppRoutes.AppointmentStatus}
                onClick={() => onClick(AppRoutes.AppointmentStatus)}
                label="Appointment status"
                expanded={expanded}
              >
                <MagnifyingGlass className={styles.sideMenuIcon} />
              </MenuItem>
            )}
            <MenuItem
              selected={route === AppRoutes.Miscellaneous}
              onClick={() => onClick(AppRoutes.Miscellaneous)}
              label="Miscellaneous"
              expanded={expanded}
            >
              <ThreeDots className={styles.threeDots} />
            </MenuItem>
          </>
        ) : (
          <MenuItem
            selected={route === AppRoutes.LoginFlow}
            label="Login"
            onClick={() => onClick(AppRoutes.LoginFlow)}
            expanded={expanded}
          >
            <Power className={styles.power} />
          </MenuItem>
        )}
      </div>
    </div>
  );
};

export default Menu;
