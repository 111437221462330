import React from 'react';
import styles from './ViewTitle.module.css';
import { ViewTitleProps } from './types';
import FormattedMessage from '../../widget-ui-toolkit/FormattedMessage';

const ViewTitle = (props: ViewTitleProps) => {
  return (
    <div className={styles.topSlot}>
      {props.children && props.children.length ? (
        props.children
      ) : (
        <div className={styles.appTitle} data-testid="appt-status-view-title">
          <FormattedMessage id={props.lokalizeKeyName ?? ''} />
        </div>
      )}
    </div>
  );
};

export default ViewTitle;
