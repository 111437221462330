import { BookedAppointmentsResponse } from '../../api/types';
import { capFirstLetter, getDayStringFromDate } from '../../utils/copy';
import {
  BookedAppointments,
  BookingAppointment,
  BookingViewObject,
  MainViewFilteringSettings,
} from './types';
import moment from 'moment';

export const getViewDate = (dateString: string): string => {
  const today = new Date();
  const yday = new Date();
  yday.setDate(yday.getDate() - 1);

  const dateParts = dateString.split('-');
  // month is zero based
  const dateObject = new Date(
    parseInt(dateParts[2]),
    parseInt(dateParts[1]) - 1,
    parseInt(dateParts[0])
  );

  if (dateObject.toDateString() === today.toDateString()) return 'Today';
  if (dateObject.toDateString() === yday.toDateString()) return 'Yesterday';

  return capFirstLetter(getDayStringFromDate(dateObject));
};

export const getSummaryMessage = (
  bookedAppointments: BookedAppointments
): string => {
  return `Showing ${Array.from(bookedAppointments).reduce(
    (acc: number, elem: [string, BookingViewObject]) => {
      const daySlot = elem[1];

      return acc + daySlot.numBookings;
    },
    0
  )} results from ${Array.from(bookedAppointments).length} days`;
};

export const getBookedAppointmentsMap = (
  bookedAppointments: BookedAppointmentsResponse
): Map<string, BookingViewObject> =>
  bookedAppointments.bookings.reduce(
    (map: Map<string, BookingViewObject>, bookingAppointment) => {
      if (map.has(bookingAppointment.dateString)) {
        const entry = map.get(bookingAppointment.dateString);

        if (entry) {
          entry.bookings.push(bookingAppointment);
          entry.numBookings = entry.numBookings + 1;
        }
      } else {
        map.set(bookingAppointment.dateString, {
          dateString: bookingAppointment.dateString,
          numBookings: 1,
          bookings: [bookingAppointment],
        });
      }

      return map;
    },
    new Map<string, BookingViewObject>()
  );

export const filterBookedAppointments = (
  bookedAppointments: Map<string, BookingViewObject>,
  filteringSettings: MainViewFilteringSettings
): Map<string, BookingViewObject> => {
  let filteredBookedAppointments: BookingAppointment[] = [];
  bookedAppointments.forEach((item, outerIndex) => {
    filteredBookedAppointments = filteredBookedAppointments.concat(
      item.bookings
    );
  });

  const searchText = filteringSettings.searchText;
  if (searchText !== null && typeof searchText === 'string') {
    let phoneSearchText = searchText;
    if (phoneSearchText?.charAt(0) === '0' && phoneSearchText?.length > 1) {
      phoneSearchText = phoneSearchText.substring(1);
    }

    filteredBookedAppointments = filteredBookedAppointments.filter((it) => {
      return (
        (it.bookingUser.nhsNumber &&
          it.bookingUser.nhsNumber.includes(searchText)) ||
        it.bookingUser.phoneNumber.includes(phoneSearchText) ||
        (it.bookingChild !== null &&
          it.bookingChild?.nhsNumber.includes(searchText))
      );
    });
  }

  const status = filteringSettings.status;
  if (status !== null && typeof status === 'string' && status !== 'SHOW_ALL') {
    filteredBookedAppointments = filteredBookedAppointments.filter((it) => {
      if (it.status === status) {
        return true;
      }
      return status === 'CANCELLED' && it.status === 'SYSTEM_CANCELLED';
    });
  }

  const orderAscending = filteringSettings.orderAscending;
  if (orderAscending) {
    filteredBookedAppointments = filteredBookedAppointments.sort(function (
      a,
      b
    ) {
      return moment(a.dateString, 'DD-MM-YYYY').diff(
        moment(b.dateString, 'DD-MM-YYYY')
      );
    });
  } else {
    filteredBookedAppointments = filteredBookedAppointments.sort(function (
      a,
      b
    ) {
      return moment(b.dateString, 'DD-MM-YYYY').diff(
        moment(a.dateString, 'DD-MM-YYYY')
      );
    });
  }

  return getBookedAppointmentsMap({ bookings: filteredBookedAppointments });
};
