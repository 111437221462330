import React, { ReactElement } from 'react';
import BookingFlow from './views/BookingFlowRouter';
import { default as AppointmentStatus } from './views/AppointmentStatus/MainView';
import { ContentViewRouterProps, AppRoutes } from './ContentViewRouter.types';
import ErrorBoundary from './views/Error/ErrorBoundary';
import LoginFlow from './views/LoginFlowRouter';
import Miscellaneous from './views/Miscellaneous/Miscellaneous';
import logout from './utils/logout';
import cn from 'classnames';
import styles from './ContentViewRouter.module.css';

const ContentViewRouter = ({
  route,
  onSetAuthenticated,
  onChangeRoute,
  onCloseContentView,
  authenticated,
}: ContentViewRouterProps): ReactElement => {
  const onLogout = () => {
    onSetAuthenticated(undefined);
    onChangeRoute(undefined);
    logout();
  };

  // if we use the route as a key is ensures that the error boundary is freshly created on each route change. NB route must remain a number
  return (
    <main
      id="main"
      className={cn(
        route === undefined && styles.collapsed,
        route !== undefined && styles.expanded,
        route === AppRoutes.LoginFlow &&
          authenticated === undefined &&
          styles.login
      )}
    >
      <ErrorBoundary
        key={route}
        onSetAuthenticated={onSetAuthenticated}
        onChangeRoute={onChangeRoute}
      >
        {route === AppRoutes.LoginFlow && (
          <LoginFlow
            onChangeRoute={onChangeRoute}
            onSetAuthenticated={onSetAuthenticated}
          />
        )}
        {route === AppRoutes.AppointmentStatus && (
          /* No routing needed here at present */
          <AppointmentStatus onChangeRoute={onChangeRoute} />
        )}
        {route === AppRoutes.BookingFlow && (
          <BookingFlow onCloseContentView={onCloseContentView} />
        )}
        {route === AppRoutes.Miscellaneous && (
          <Miscellaneous onLogout={onLogout} />
        )}
      </ErrorBoundary>
    </main>
  );
};
export default ContentViewRouter;
