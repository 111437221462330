import React, { useContext, useEffect, useState } from 'react';

import ScrollContainer from '../../components/ScrollContainer';
import AppointmentStatusSummaryMessage from '../../components/AppointmentStatusSummaryMessage';
import AppointmentStatusDayCard from '../../components/AppointmentStatusDayCard';
import styles from './MainView.module.css';
import { AppRoutes } from '../../ContentViewRouter.types';

import { getBookedAppointments } from '../../api/api';
import {
  BookedAppointments,
  BookingViewObject,
  MainViewFilteringSettings,
  MainViewProps,
} from './types';
import {
  filterBookedAppointments,
  getBookedAppointmentsMap,
  getSummaryMessage,
  getViewDate,
} from './MainView.view';
import LoadingView from '../../components/Loading/LoadingView';
import AppointmentStatusNoBookingsView from './AppointmentStatusNoBookingsView';
import { BookedAppointmentsResponse } from '../../api/types';
import { SizeContext } from '../../utils/SizeContext';
import AppointmentStatusSearchCard from '../../components/AppointmentStatusSearchCard';
import { usePracticeSettings } from '../BookingFlow/hooks';

const MainView = ({ onChangeRoute }: MainViewProps) => {
  const practiceSettings = usePracticeSettings(true);
  const [bookedAppointments, setBookedAppointments] = useState<
    BookedAppointments
  >();

  const [filteringSettings] = useState<MainViewFilteringSettings>({
    orderAscending: false,
  });

  const [filteredBookedAppointments, setFilteredBookedAppointments] = useState<
    BookedAppointments
  >();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const onSizeUpdate = useContext(SizeContext);

  useEffect(() => {
    getBookedAppointments().then(
      (bookedAppointments: BookedAppointmentsResponse) => {
        const bookedAppontmentsMap = getBookedAppointmentsMap(
          bookedAppointments
        );
        setBookedAppointments(bookedAppontmentsMap);
        setFilteredBookedAppointments(
          filterBookedAppointments(bookedAppontmentsMap, filteringSettings)
        );
        setIsLoading(false);
        onSizeUpdate();
      }
    );
  }, []);

  const getAppointmentStatusDayCards = (
    bookedAppointments: BookedAppointments,
    highlightText?: string
  ) => {
    const bookedAppointmentsArray: [string, BookingViewObject][] = Array.from(
      bookedAppointments
    );

    return bookedAppointmentsArray.map((item, outerIndex) => {
      const bookingInfo = item[1];
      const isLast = outerIndex === bookedAppointmentsArray.length - 1;

      return (
        <AppointmentStatusDayCard
          bookings={bookingInfo.bookings}
          dayOfTheWeek={getViewDate(bookingInfo.dateString)}
          date={bookingInfo.dateString}
          isLastDay={isLast}
          highlightText={highlightText}
          partnership={practiceSettings.partnership}
        />
      );
    });
  };

  return (
    <div
      className={styles.apptStatusViewContainer}
      data-testid="appt-status-view"
    >
      {isLoading && <LoadingView />}
      {!isLoading &&
        (bookedAppointments?.size ? (
          <>
            <AppointmentStatusSearchCard
              onSortingOrderingUpdate={(orderAscending) => {
                filteringSettings.orderAscending = orderAscending;
                setFilteredBookedAppointments(
                  filterBookedAppointments(
                    bookedAppointments!,
                    filteringSettings
                  )
                );
              }}
              onStatusFilteringUpdate={(status) => {
                filteringSettings.status = status;
                setFilteredBookedAppointments(
                  filterBookedAppointments(
                    bookedAppointments!,
                    filteringSettings
                  )
                );
              }}
              onSearchTextUpdate={(searchText) => {
                filteringSettings.searchText = searchText;
                setFilteredBookedAppointments(
                  filterBookedAppointments(
                    bookedAppointments!,
                    filteringSettings
                  )
                );
              }}
              partnership={practiceSettings.partnership}
            />
            <AppointmentStatusSummaryMessage
              message={getSummaryMessage(filteredBookedAppointments!) ?? ''}
            />
            <ScrollContainer href={'#scrollReset'}>
              {getAppointmentStatusDayCards(
                filteredBookedAppointments!,
                filteringSettings.searchText
              )}
            </ScrollContainer>
          </>
        ) : (
          <AppointmentStatusNoBookingsView
            onClick={() => onChangeRoute(AppRoutes.BookingFlow)}
          />
        ))}
    </div>
  );
};

export default MainView;
