import React, { ReactElement, useState, useContext, useEffect } from 'react';
import { ReactComponent as LeftChevron } from '../../../media/LeftChevron.svg';
import { ReactComponent as RightChevron } from '../../../media/RightChevron.svg';
import { SizeContext } from '../../utils/SizeContext';
import SingleStepPagination from '../Pagination/SingleStepPagination';
import { TimeSlot } from '../../components/TimeScheduleInput/types';
import { TimeScheduleInputProps } from './types';
import TimeScheduleSlot from './TimeScheduleSlot';
import TimeScheduleEmpty from './TimeScheduleEmpty';
import { ENTRIES_PER_PAGE } from './constants';
import FormattedMessage from '../../widget-ui-toolkit/FormattedMessage';
import styles from './TimeScheduleInput.module.css';
import { trackEvent } from '../../utils/eventTracking';

const LOCALIZE_PREFIX = 'widget.booking.calendar';

const TimeScheduleInput = ({
  onChange,
  slots,
  value,
  unavailabilityMessage,
}: TimeScheduleInputProps): ReactElement => {
  let startPage = 0;
  if (value) {
    const slotIndex = slots.map((x) => x.date).indexOf(value);
    const slotNotFound = slotIndex === -1;
    startPage = slotNotFound ? 0 : Math.floor(slotIndex / ENTRIES_PER_PAGE);
  }
  const [page, setPage] = useState(0);
  const numPages = Math.ceil(slots.length / ENTRIES_PER_PAGE);
  const onSizeUpdate = useContext(SizeContext);

  const firstSlotDate = slots[0] && slots[0].date;

  useEffect(() => {
    setPage(startPage);
  }, [firstSlotDate, startPage]);

  useEffect(() => {
    if (value) {
      const unavailableSlots = slots.filter((x) => x.disabled).length;
      const availability = slots.length
        ? 100 - (unavailableSlots / slots.length) * 100
        : 0;
      trackEvent({
        action: 'calendar-viewed',
        label: availability.toString(),
        property: value.toLocaleDateString('sv') + '-screen' + page,
      });
    }
  }, [page, value && value.getDate()]); //eslint-disable-line react-hooks/exhaustive-deps

  const slice = slots.slice(
    page * ENTRIES_PER_PAGE,
    (page + 1) * ENTRIES_PER_PAGE
  );

  const changePage = (page: number) => {
    setPage(page);
    onSizeUpdate();
    onChange(value as Date, false);
  };

  return (
    <div>
      <div className={styles.timeScheduleInput}>
        {slice.length ? (
          <>
            {slice.map((x: TimeSlot) => (
              <TimeScheduleSlot
                selected={x.date === value}
                onClick={() => onChange(x.date, true)}
                key={x.date.getTime()}
                date={x.date}
                disabled={x.disabled}
              />
            ))}
            <SingleStepPagination
              onChange={changePage}
              page={page}
              numPages={numPages}
            >
              {[
                <label className={styles.paginationButton} key="left">
                  <LeftChevron className={styles.chevron} />
                  <span>
                    <FormattedMessage
                      id={`${LOCALIZE_PREFIX}.display_earlier_timeslots`}
                    />
                  </span>
                </label>,
                <label className={styles.paginationButton} key="right">
                  <span>
                    <FormattedMessage
                      id={`${LOCALIZE_PREFIX}.display_later_timeslots`}
                    />
                  </span>
                  <RightChevron className={styles.chevron} />
                </label>,
              ]}
            </SingleStepPagination>
          </>
        ) : (
          <TimeScheduleEmpty unavailabilityMessage={unavailabilityMessage} />
        )}
      </div>
    </div>
  );
};

export default TimeScheduleInput;
