import { ValidationErrors } from './types';

const NHS_NUMBER_LENGTH = 10;

export const validateNhsNumber = (nhsNumber?: string): ValidationErrors => {
  if (!nhsNumber) return ValidationErrors.NhsNumberError;
  nhsNumber = nhsNumber.replace(/[\s-]/g, '');
  //Make sure the Nhs number is 10 dgits.
  if (!nhsNumber.match(/^\d{10}$/)) {
    return ValidationErrors.NhsNumberError;
  }
  let total = 0;
  let checkDigit = 0;
  for (let i = NHS_NUMBER_LENGTH; i > 0; --i) {
    const c = nhsNumber.charAt(i - 1);
    const digitValue = parseInt(c);
    if (i === NHS_NUMBER_LENGTH) {
      checkDigit = digitValue;
    } else {
      total += digitValue * (11 - i);
    }
  }

  const remainder = total % 11;
  const checksum = remainder === 0 ? 0 : 11 - remainder;
  if (checkDigit === checksum && checksum !== 10)
    return ValidationErrors.NoError;
  return ValidationErrors.NhsNumberError;
};
