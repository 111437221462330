import React from 'react';
import Button from '../../widget-ui-toolkit/Button';
import FormattedMessage from '../../widget-ui-toolkit/FormattedMessage';
import styles from './AppointmentStatusNoBookingsView.module.css';
import { AppointmentStatusNoBookingsViewProps } from './types';
import { ReactComponent as NotePadAndPen } from '../../../media/NotePadAndPen.svg';

const LOCALIZE_PREFIX = 'widget.appointment_status.no_bookings';

const AppointmentStatusNoBookingsView = ({
  onClick,
}: AppointmentStatusNoBookingsViewProps) => {
  return (
    <div
      className={styles.wrapper}
      data-testid={'appt-status-no-bookings-view'}
    >
      <NotePadAndPen />
      <div className={styles.noBookingsTitle}>
        <FormattedMessage id={`${LOCALIZE_PREFIX}.title`} />
      </div>
      <div className={styles.noBookingsMessage}>
        <FormattedMessage id={`${LOCALIZE_PREFIX}.message`} />
      </div>
      <Button className={styles.noBookingsCtaButton} onClick={onClick}>
        <FormattedMessage id={`${LOCALIZE_PREFIX}.book_button`} />
      </Button>
    </div>
  );
};

export default AppointmentStatusNoBookingsView;
