import React, { ReactElement } from 'react';
import Button from '.';
import FormattedMessage from '../FormattedMessage';
import styles from './Button.module.css';
import { ReactComponent as LeftChevron } from '../../../media/LeftChevron.svg';
import { BackButtonProps } from './types';

const LOCALIZE_PREFIX = 'widget.booking.main';

const BackButton = ({ onClick }: BackButtonProps): ReactElement => (
  <Button onClick={onClick} className={styles.backButton}>
    <LeftChevron className={styles.backChevron} />
    <FormattedMessage id={`${LOCALIZE_PREFIX}.back`} />
  </Button>
);

export default BackButton;
