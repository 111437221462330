import React, { ReactElement } from 'react';
import { ButtonProps } from './types';
import cn from 'classnames';
import styles from './Button.module.css';

const Button = ({
  onClick,
  children,
  secondary,
  transparent,
  disabled,
  alert,
  className,
  title,
}: ButtonProps): ReactElement => {
  let type = styles.primary;
  if (alert && secondary) {
    type = styles.secondaryAlert;
  } else if (secondary) {
    type = styles.secondary;
  } else if (transparent) {
    type = styles.transparent;
  } else if (alert) {
    type = styles.alert;
  }

  return (
    <button
      className={cn(styles.button, type, className)}
      disabled={disabled}
      onClick={onClick}
      title={title}
    >
      {children}
    </button>
  );
};

export default Button;
