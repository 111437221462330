import { ValidationErrors } from './types';

export const validateName = (name?: string): ValidationErrors => {
  if (!name) return ValidationErrors.NameError;
  name = name.replace(/[\s-]/g, '');

  if (!name.match(/^[a-zA-Z]+$/)) {
    return ValidationErrors.NameError;
  }

  return ValidationErrors.NoError;
};
