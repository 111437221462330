import React from 'react';
import styles from './BookingStatusCard.module.css';
import { BookingStatusCardProps } from './types';
import FormattedMessage from '../../widget-ui-toolkit/FormattedMessage';

const LOCALIZE_PREFIX = 'widget.appointment_status.booking.status';

const BookingStatusCard = (props: BookingStatusCardProps) => {
  const statuses = {
    CREATED: (
      <div>
        <div className={styles.unconfirmedBookingCardCircleStatus} />{' '}
        <FormattedMessage id={LOCALIZE_PREFIX + `.unconfirmed`} />
      </div>
    ),
    CONFIRMED: (
      <div>
        <div className={styles.confirmedBookingCardCircleStatus} />{' '}
        <FormattedMessage id={LOCALIZE_PREFIX + `.confirmed`} />
      </div>
    ),
    CANCELLED: (
      <div>
        <div className={styles.cancelledBookingCardCircleStatus} />{' '}
        <FormattedMessage id={LOCALIZE_PREFIX + `.cancelled`} />
      </div>
    ),
    SYSTEM_CANCELLED: (
      <div>
        <div className={styles.systemCancelledBookingCardCircleStatus} />{' '}
        <FormattedMessage id={LOCALIZE_PREFIX + `.system_cancelled`} />
      </div>
    ),
    FINISHED: (
      <div>
        <div className={styles.completedBookingCardCircleStatus} />{' '}
        <FormattedMessage id={LOCALIZE_PREFIX + `.completed`} />
      </div>
    ),
  };

  return (
    <>
      <p className={styles.bookingStatusCard}>
        {statuses[props.appointmentStatus as keyof typeof statuses]}
      </p>
    </>
  );
};

export default BookingStatusCard;
