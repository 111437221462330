import React, { useState } from 'react';
import styles from './AppointmentStatusSearchCard.module.css';
import { AppointmentStatusSearchCardProps } from './types';
import SearchTextInput from '../../widget-ui-toolkit/TextInput/SearchTextInput';
import SelectComponent from '../../widget-ui-toolkit/Select/Select';
import { localize } from '../../widget-ui-toolkit/FormattedMessage/localize';
import { Locale } from '../../widget-ui-toolkit/FormattedMessage/types';

const LOCALIZE_PREFIX = 'widget.appointment_status.booking.search.';

const nhsFilterOptions = [
  {
    label: localize(LOCALIZE_PREFIX + 'filter.status.unconfirmed', Locale.en),
    value: 'CREATED',
  },
  {
    label: localize(LOCALIZE_PREFIX + 'filter.status.confirmed', Locale.en),
    value: 'CONFIRMED',
  },
  {
    label: localize(LOCALIZE_PREFIX + 'filter.status.completed', Locale.en),
    value: 'FINISHED',
  },
  {
    label: localize(LOCALIZE_PREFIX + 'filter.status.cancelled', Locale.en),
    value: 'CANCELLED',
  },
  {
    label: localize(LOCALIZE_PREFIX + 'filter.status.show_all', Locale.en),
    value: 'SHOW_ALL',
  },
];

const AppointmentStatusSearchCard = (
  props: AppointmentStatusSearchCardProps
) => {
  const [status, setStatus] = useState<string>('SHOW_ALL');
  const [sortingOrderAscending, setSortingOrderAscending] = useState<string>(
    'false'
  );
  return (
    <>
      <SearchTextInput
        onChange={(e) => props.onSearchTextUpdate(e.target.value)}
        label={localize(LOCALIZE_PREFIX + 'search_text_box_label', Locale.en)}
        id="searchByPatientNhsOrPhoneNumber"
        placeholder={localize(
          LOCALIZE_PREFIX + 'search_text_box_placeholder',
          Locale.en
        )}
        imageAltText=""
      />
      <div className={styles.filtersContainer}>
        <SelectComponent
          className={styles.selectStyle}
          onChange={(value) => {
            setSortingOrderAscending(value);
            props.onSortingOrderingUpdate(value === 'true');
          }}
          selected={sortingOrderAscending}
          options={[
            {
              label: localize(LOCALIZE_PREFIX + 'sort.newest', Locale.en),
              value: 'false',
            },
            {
              label: localize(LOCALIZE_PREFIX + 'sort.oldest', Locale.en),
              value: 'true',
            },
          ]}
        ></SelectComponent>
        <div className={styles.iconInputDivider}></div>
        <div className={styles.filterByStatusContainer}>
          <SelectComponent
            className={styles.filterByStatusSelectStyle}
            onChange={(value) => {
              setStatus(value);
              props.onStatusFilteringUpdate(value);
            }}
            selected={status}
            options={nhsFilterOptions}
          ></SelectComponent>
        </div>
      </div>
    </>
  );
};

export default AppointmentStatusSearchCard;
