import axios from 'axios';
import { camelizeJson } from './camelize';

type RequestConfig = {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  headers?: Record<string, string>;
  body?: object;
};

export default async function request<T>(
  url: string,
  config: RequestConfig,
  camelizeResponse: boolean = true
): Promise<T> {
  const response = await axios.request({
    ...config,
    url,
    method: config.method || 'GET',
    data: config.body,
  });
  return camelizeResponse ? camelizeJson(response.data) : response.data;
}
