import React, { ReactElement } from 'react';
import Button from '../../widget-ui-toolkit/Button';
import { SingleStepPaginationProps } from './types';
import PaginationDots from './PaginationDots';
import styles from './SingleStepPagination.module.css';

const SingleStepPagination = ({
  page,
  numPages,
  onChange,
  children,
}: SingleStepPaginationProps): ReactElement => {
  return (
    <div className={styles.wrapper}>
      <Button
        onClick={() => onChange(page - 1)}
        disabled={page === 0}
        transparent
      >
        {children[0]}
      </Button>
      <PaginationDots selectedDot={page} numDots={numPages} />
      <Button
        onClick={() => onChange(page + 1)}
        disabled={page + 1 === numPages}
        transparent
      >
        {children[1]}
      </Button>
    </div>
  );
};

export default SingleStepPagination;
