import React from 'react';
import Button from '../../widget-ui-toolkit/Button';
import cn from 'classnames';
import { TimeScheduleSlotProps } from './types';
import styles from './TimeScheduleInput.module.css';

const TimeScheduleSlot = ({
  date,
  disabled,
  selected,
  onClick,
}: TimeScheduleSlotProps) => {
  const hour = date.getHours();
  const minute = date.getMinutes();
  return (
    <div
      className={cn(
        styles.slot,
        selected && styles.selected,
        disabled && styles.disabled
      )}
    >
      <Button transparent onClick={onClick} disabled={disabled}>
        {hour.toString().padStart(2, '0')}:{minute.toString().padStart(2, '0')}
      </Button>
    </div>
  );
};

export default TimeScheduleSlot;
