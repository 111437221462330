import React from 'react';
import Alert from '../../widget-ui-toolkit/Alert';
import { ReactComponent as Calendar } from '../../../media/Calendar.svg';
import FormattedMessage from '../../widget-ui-toolkit/FormattedMessage';
import styles from './TimeScheduleEmpty.module.css';
import { TimeScheduleEmptyProps } from './types';

const LOCALIZE_PREFIX = 'widget.booking.calendar';

const TimeScheduleEmpty = ({
  unavailabilityMessage,
}: TimeScheduleEmptyProps) => {
  return (
    <Alert>
      <div className={styles.timeSchedule}>
        <Calendar className={styles.calendar} />
        <span className={styles.messageWrapper}>
          <p className={styles.message}>
            <FormattedMessage
              id={
                unavailabilityMessage !== null
                  ? unavailabilityMessage.message
                  : `${LOCALIZE_PREFIX}.no_available_slots_for_date_alert`
              }
            />
          </p>
        </span>
      </div>
    </Alert>
  );
};

export default TimeScheduleEmpty;
