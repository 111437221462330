import React from 'react';
import TextInput from './TextInput';
import { SearchTextInputProps } from './types';
import { ReactComponent as MagnifyingGlass } from '../../../media/MagnifyingGlass.svg';
import cn from 'classnames';
import styles from './SearchTextInput.module.css';

const SearchTextInput = ({
  validationError,
  defaultValue,
  label,
  id,
  imageAltText,
  ...props
}: SearchTextInputProps) => {
  return (
    <TextInput
      validationError={validationError}
      defaultValue={defaultValue}
      label={label}
      id={id}
      {...props}
    >
      <div className={styles.searchTextInputWrapper}>
        <div
          className={cn(
            styles.iconInputDivider,
            validationError && styles.invalid
          )}
        ></div>
        {!validationError && <MagnifyingGlass className={styles.searchIcon} />}
      </div>
    </TextInput>
  );
};

export default SearchTextInput;
