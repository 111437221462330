import React from 'react';
import { MONTH_NAMES } from './constants';
import { getMonday } from '../../utils/date';
import FormattedMessage from '../../widget-ui-toolkit/FormattedMessage';
import styles from './DateTimeMonthYearLabel.module.css';

const LOCALIZE_PREFIX = 'widget.booking.calendar';

const DateTimeMonthYearLabel = ({ date }: { date: Date }) => {
  if (!date) {
    return <div className={styles.monthYearLabel}>N/A</div>;
  }
  const firstDayOfWeek = getMonday(date);
  const month = MONTH_NAMES[firstDayOfWeek.getMonth()];
  const year = date.getFullYear();
  return (
    <div className={styles.monthYearLabel}>
      <FormattedMessage id={`${LOCALIZE_PREFIX}.${month}`} /> {year}
    </div>
  );
};

export default DateTimeMonthYearLabel;
